import * as React from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import { DashboardStyles } from "../../assets/styles/dashboard.styles";
import AddIcon from '@mui/icons-material/Add';
import PackageTable from "./package.table";
import {useNavigate} from "react-router-dom";
const PackagePage = () => {

  const styles = DashboardStyles();
  const navigate = useNavigate();

  return (
    <Container style={{maxWidth: '95%'}}>
      <Box sx={styles.titleWrap}>
        <Box>
          <Typography variant='h1' sx={styles.title}>
            Package List
          </Typography>
          <Typography variant='h5' sx={styles.subTitleAdmin}>
            Current Information
          </Typography>
        </Box>
        <Box sx={styles.wrapBtn}>
          <Button
            sx={[styles.btn, styles.btnPrimaryLight, styles.downloadBtn]}
            onClick={()=>{navigate('/admin/package/create')}}
          >
            <AddIcon/>
            Create a Package
          </Button>
        </Box>
      </Box>
      <PackageTable />
    </Container>
  );
}

export default PackagePage;

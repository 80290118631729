export const PaginationStyles = () => ({
  pagination: {
    border: '1px solid #e0e0e0',
    borderRadius: '100px',
    '& .MuiPagination-ul': {
      '& li': {
        borderLeft: '1px solid #e0e0e0',
        '& .MuiButtonBase-root': {
          color: 'rgba(120, 125, 134, 0.5)',
          borderRadius: '0',
          margin: 0,
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        },
        '& .Mui-selected': {
          color: 'rgba(0, 0, 0, 1)',
          backgroundColor: '#fff',
        },
      },
      '& li:first-of-type, li:last-of-type': {
        borderLeft: 'none',
        '& .MuiButtonBase-root': {
          color: 'rgba(0, 0, 0, 1)',
          backgroundColor: 'transparent',
        },
      },
      '& li:last-of-type': {
        borderLeft: '1px solid #e0e0e0',
      },
    },
  },
});

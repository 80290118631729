import { useState } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import download from '../../assets/images/download.svg';
import { DashboardStyles } from '../assets/styles/dashboard.styles';
import DashboardTable from './dashboard.table.js';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import ReportsService from '../../core/services/reports.service';
import AppHelper from '../../core/helpers/AppHelper';
import { useUI } from '../../core/context/ui';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import DialogMonthlyReport from '../admin/monthly-report.dialog.js';

const Dashboard = () => {

  const { snackbarUI } = useUI();
  const [loader, setLoader] = useState(false);
  const reportsService = new ReportsService();
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;

  // monthly report dialog
  const [openMRDialog, setOpenMRDialog] = useState(false);

  const ridersType = [
    { id: '1', riderType: 'ascap' },
    { id: '2', riderType: 'sesac' },
    { id: '3', riderType: 'bmi' },
    { id: '4', riderType: 'monthly' },
  ];

  const getRiders = async (riderType) => {
    try {
      if (riderType !== 'monthly') {
        setLoader(true);
        reportsService.setAccessToken(accessToken)
        // eslint-disable-next-line no-unused-vars
        const response = await reportsService.getRiders(riderType);
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let file = document.createElement('a');
        file.href = url;
        file.download = `${riderType}_rider_${Moment().format('YYYYMMDDHms')}.xlsx`;
        file.click();
        setLoader(false);
      } else {
        setOpenMRDialog(true)
      }
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const styles = DashboardStyles();
  return (
    <>
      <Container style={{ maxWidth: '95%' }}>
        <Box sx={styles.titleWrap}>
          <Box>
            <Typography variant='h1' sx={styles.title}>
              Licensing
            </Typography>
            <Typography variant='h4' sx={styles.subTitle}>
              Search all gyms with music licensing
            </Typography>
          </Box>
          <Box sx={styles.wrapBtn}>
          {ridersType.map((data) => (
            <Button key={data.id} onClick={() => getRiders(`${data.riderType}`)} sx={[styles.btn, styles.btnPrimaryLight, styles.downloadBtn]}>
                <Box component='img' src={download} sx={styles.ico} />
                {data.riderType} {data.riderType !== 'monthly' ? 'Rider' : 'Report'}
              </Button>
          ))}
          </Box>
        </Box>
        <DashboardTable />
      </Container>
      {
        openMRDialog && (      
        <DialogMonthlyReport
          openDialog={openMRDialog}
          setOpenDialog={setOpenMRDialog}
        />)
      }
      <LoaderWidget loader={loader} />
    </>
  );
};

export default Dashboard;

import * as React from 'react';

import { words as _words } from 'lodash';

import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector,
} from '@mui/x-data-grid';

import UserService from '../../../core/services/admin.service';
import { DataTable } from '../admin-dashboard.table';
import { PaginationStyles } from './custom-pagination.styles';

export const CustomPagination = () => {
  const { rowsState, setRowsState, paginationURL, token } = React.useContext(DataTable);

  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const query = _words(paginationURL, /[^&]+/g).slice(0, -1).join('&');

  const styles = PaginationStyles();

  const userService = new UserService();

  const onPageChange = async (nextPage) => {
    try {
      userService.setAccessToken(token);
      const response = await userService.pagination(query, nextPage);
      setRowsState({
        ...rowsState,
        rows: response.data.data,
        rowCount: Number(response.data.total),
        page: response.data.currentPage - 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Pagination
      color='primary'
      count={pageCount}
      page={page + 1}
      siblingCount={0}
      boundaryCount={1}
      onChange={(event, value) => onPageChange(value)}
      sx={styles.pagination}
    />
  );
};

import React from 'react';

import { useSelector } from 'react-redux';

import AdminDashboard from './admin/admin-dashboard.page';
import Dashboard from './dashboard/dashboard';

const DashboardBranch = () => {
  const { user } = useSelector((state) => state.user);
  const role = user?.roles[0]?.name;

  return <>{role === 'admin' ? <Dashboard /> : <AdminDashboard />}</>;
};

export default DashboardBranch;

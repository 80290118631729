import { useState, useRef, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";

import { Alert, Box, Button, Dialog, Grid } from "@mui/material";

import {
  BtnActionsDlgAsk,
  ContentSectionDialog,
} from "../assets/styles/admin-dashboard.dialog.styles";
import LocalitiesService from "../../core/services/localities.service";
import FormikAutocomplete from "../../core/widgets/autocomplete/autocomplete.widget";
import InputField from "../../core/widgets/input.widget";
import businessModel from "../dashboard/models/business.model";
import businessInitialValues from "../dashboard/models/business.initial.values";
import businessValidationSchema from "../dashboard/models/business.validation.schema";
import SelectField from "../../core/widgets/select.widget";
import AppHelper from "../../core/helpers/AppHelper";
import BusinessService from "../../core/services/business.service";

const DialogEditAccount = ({
  data,
  openDialog,
  setOpenDialog,
  onEditSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);

  const [cityId, setCityId] = useState(data.stateId || '');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { formId, formField } = businessModel;
  const formikRef = useRef();

  const gymType = ["Commercial Gym", "Small Studio"];

  const localitiesService = new LocalitiesService();

  const getStates = async () => {
    try {
      const response = await localitiesService.listStates();
      setStates(response.data);
    } catch (error) {
      AppHelper.getError(error);
    }
  };

  const getCities = async (stateId) => {
    try {
      setCities([]);
      const response = await localitiesService.listCities(stateId);
      setCities(response.data);
    } catch (error) {
      AppHelper.getError(error);
    }
  }

  const businessService = new BusinessService();

  const onSubmit = (formData) => {
      setLoading(true);
      businessService.editBusiness(data?.businessId, {
        ...formData, 
        state: formData.state.label,
        city: formData.city.label,
      }).then(() => {
        onEditSuccess((prev) => !prev);
        setOpenDialog(false);
        return setLoading(false);
      }).catch((error) => {
        setLoading(false);
        setErrror(true);
        console.log(error);
      });
    }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cityId) {
      getCities(cityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId])


  const getVal = (val) => {
    // setIsBusinessStateChanged(true);
    if(!val) return setCities([]);
    setCityId(val.value);
  }

  return (
    <Dialog
      open={openDialog}
      keepMounted
      onClose={handleDialogClose}
      PaperProps={{ sx: { padding: "16px" } }}
      maxWidth="md"
    >
      <Formik
        enableReinitialize
        initialValues={businessInitialValues(data)}
        validationSchema={businessValidationSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        <Form id={formId} component="form">
          <ContentSectionDialog>
            <Box sx={{ mb: 3 }}>Edit Business Information</Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  name={formField.businessName.name}
                  label={formField.businessName.label}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fullWidth
                  name={formField.fullName.name}
                  label={formField.fullName.label}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fullWidth
                  name={formField.email.name}
                  label={formField.email.label}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  name={formField.phoneNumber.name}
                  label={formField.phoneNumber.label}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  name={formField.title.name}
                  label={formField.title.label}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  name={formField.gymType.name}
                  label={formField.gymType.label}
                  data={gymType}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  fullWidth
                  name={formField.address.name}
                  label={formField.address.label}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name={formField.state.name}
                  label={formField.state.label}
                  defaultValue={{ value: data.stateId, label: data.state }}
                  component={FormikAutocomplete}
                  getValueChild={getVal}
                  options={states}
                  textFieldProps={{
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                  }}
                  disabled={!states.length}
                />
              </Grid>
              <Grid item xs={3}>
              <Field
                  name={formField.city.name}
                  label={formField.city.label}
                  component={FormikAutocomplete}
                  defaultValue={{ value: data.city, label: data.city }}
                  isOptionEqualToValue
                  getValueChild={()=>{}}
                  options={cities}
                  textFieldProps={{
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                  }}
                  disabled={!cities.length}
                />
              </Grid>
              <Grid item xs={3}>
                <InputField
                  fullWidth
                  name={formField.zipcode.name}
                  label={formField.zipcode.label}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  name={formField.classesPerWeek.name}
                  label={formField.classesPerWeek.label}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  name={formField.numberGroup.name}
                  label={formField.numberGroup.label}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  fullWidth
                  name={formField.totalMembers.name}
                  label={formField.totalMembers.label}
                />
              </Grid>
            </Grid>
          </ContentSectionDialog>
          {error && <Alert severity="warning">Fail to edit the customer, try again.</Alert>}
          <BtnActionsDlgAsk>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleDialogClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              type="submit"
            >
              Edit
            </Button>
          </BtnActionsDlgAsk>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default DialogEditAccount;

DialogEditAccount.propTypes = {
  data: PropTypes.shape({
    businessId: PropTypes.number,
    name: PropTypes.string,
    fullName: PropTypes.string,
    state: PropTypes.string,
    stateId: PropTypes.string,
    city: PropTypes.string,
  }),
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  onEditSuccess: PropTypes.func.isRequired,
};

import { createContext, useEffect, useState } from 'react';

import { isEmpty as _isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Download } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import CustomNoRowsOverlay from '../../assets/CustomNoRowsOverlay.js';
import { useUI } from '../../core/context/ui.js';
import AppHelper from '../../core/helpers/AppHelper.js';
import CustomerService from '../../core/services/customer.service.js';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import { theme } from '../../theme';
import { DashboardStyles } from '../assets/styles/dashboard.styles.js';
import { columns } from './columns';
import CustomFilter from './custom-filter.table.js';
import { CustomPagination } from './custom-pagination.table.js';
import DialogEditCustom from './edit-custom-dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogAskDelete from './ask-delete.dialog.js';
import AskDropDialog from './ask-drop.dialog.js';

const sx = {
  '&:hover': {
    transition: 'all 0.2s',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    '& .MuiListItemIcon-root': {
      transition: 'all 0.2s',
      color: theme.palette.primary.color,
    },
  },
};

const DashboardTable = () => {

  const location = useLocation()
  const customerService = new CustomerService();
  const { snackbarUI } = useUI();
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    page: location?.state?.page ?? 1,
  });
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [providerSelected, setProviderSelected] = useState(0);
  const [sortModel, setSortModel] = useState({
    field: '',
    sort: '',
  });
  const [openDlgAskDelete, setOpenDlgAskDelete] = useState(false);
  const [openDialogDlt, setOpenDialogDlt] = useState(false);
  const [customerID, setCustomerID] = useState('');
  const [openDlgEditCustom, setOpenDlgEditCustom] = useState(false);
  const [customerSelectedId, setCustomerSelectedId] = useState(null);

  const [openDlgDrop, setOpenDlgDrop] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const navigate = useNavigate();

  const styles = DashboardStyles();

  const getCustomers = async (...params) => {
    try {
      setLoader(true);
      customerService.setAccessToken(accessToken);
      const r1 = await customerService.getCustomers(params[0]);
      setRowsState({
        ...rowsState,
        rows: r1.data.data,
        rowCount: Number(r1.data.recordsTotal),
        page: Number(r1.data.page) - 1,
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      AppHelper.checkError(error, snackbarUI);
    }
  };

  useEffect(() => {
    getCustomers({
      order: sortModel.field,
      orderDir: sortModel.sort,
      page: rowsState.page,
      paymentStatus,
      provider: providerSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, reloadData]);

  const props = {
    accessToken,
    rowsState,
    paymentStatus,
    providerSelected,
    sortModel,
    getCustomers,
    setRowsState,
    setPaymentStatus,
    setProviderSelected,
  };

  const getCustomerAgreements = async (id) => {
    try {
      setLoader(true);
      customerService.setAccessToken(user.token);
      const response = await customerService.downloadAgreement(id);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `agreements_${id}.zip`;
      file.click();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const handleReloadSuccess = () => {
    setReloadData(prevState => !prevState);
  };

  const agreementColumn = [
    {
      field: 'agreementsLink',
      headerAlign: 'center',
      headerName: 'Agreements',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      align: 'center',
      renderCell: (params) => (
        <IconButton onClick={() => getCustomerAgreements(params.row.id)}>
          <Download />
        </IconButton>
      ),
    },
  ];

  const actionsColumn = [
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.2,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'actionHeader',
      getActions: (params) => [
        <GridActionsCellItem
          label='Edit'
          onClick={() => {
            setOpenDlgEditCustom(true);
            setCustomerSelectedId(params.id);
          }}
          showInMenu
          key={0}
          sx={sx}
        />,
        <GridActionsCellItem
          label='Delete'
          showInMenu
          key={1}
          sx={sx}
          onClick={() => {
            setOpenDialogDlt(!openDialogDlt);
            setOpenDlgAskDelete(true);
            setCustomerID(params.row.id);
          }}
        />,
        <GridActionsCellItem
          label='Drop'
          showInMenu
          disabled={params.row.paymentStatus === 'DROP'}
          key={1}
          sx={sx}
          onClick={() => {
            setOpenDlgDrop(!openDialogDlt);
            setCustomerSelectedId(params.row.id);
          }}
      />,
      ],
    },
  ];

  const handleSortModelChange = (sortModel) => {
    if (_isEmpty(sortModel)) {
      getCustomers({});
      setSortModel({ field: '', sort: '' });
    } else {
      setLoader(true);
      const { field, sort } = sortModel[0];
      setSortModel({ field, sort });
      getCustomers({ order: field, direction: sort });
    }
  };

  return (
    <>
      <Paper elevation={4} sx={styles.wrapperTable}>
        <DashboardContext.Provider value={props}>
          <CustomFilter />
          <DataGrid
            columns={[...columns, ...agreementColumn, ...actionsColumn]}
            {...rowsState}
            headerHeight={56}
            rowHeight={56}
            onRowClick={(event) => navigate(`/admin/customer/${event.id}`, {state: { page: rowsState.page + 1}})}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay: CustomNoRowsOverlay,
            }}
            pagination
            paginationMode='server'
            disableSelectionOnClick
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            checkboxSelection={false}
            sx={styles.dataGridStyles}
          />
        </DashboardContext.Provider>
      </Paper>
      <LoaderWidget loader={loader} />
      {openDialogDlt && (
        <DialogAskDelete
          openDialog={openDlgAskDelete}
          setOpenDialog={setOpenDlgAskDelete}
          getUsers={getCustomers}
          customerID={customerID}
          setLoader={setLoader}
          setOpenDialogDlt={setOpenDialogDlt}
        />
      )}
      {openDlgEditCustom && (
        <DialogEditCustom
          openDialog={openDlgEditCustom}
          setOpenDialog={setOpenDlgEditCustom}
          id={customerSelectedId}
        />
      )}
      {openDlgDrop && (
        <AskDropDialog 
          openDialog={openDlgDrop}
          setOpenDialog={setOpenDlgDrop}
          onSuccess={handleReloadSuccess}
          data={customerSelectedId}
        />
      )}
    </>
  );
};

export const DashboardContext = createContext({});
export default DashboardTable;

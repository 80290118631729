export const wrapperPros = {
  border: 'solid 1px #f5a623',
  borderRadius: '10px',
  padding: '28px',
  marginTop: '21px',
};

export const optionPro = {
  paddingBottom: '18px',
  fontWeight: 'bold',
  fontSize: '18px',
};
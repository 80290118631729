import React from 'react';

import { debounce as _debounce, isUndefined as _isUndefined, split as _split } from 'lodash';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box, TextField, Typography,
} from '@mui/material';

import { useUI } from '../../../core/context/ui';
import AppHelper from '../../../core/helpers/AppHelper';
import { DashboardStyles } from '../../assets/styles/dashboard.styles';
import PackageService from "../../../core/services/package.service";
import {PackageContext} from "./package.table";

const PackageFilter = () => {
  const {
    rowsState,
    setRowsState,
    token,
    getPackages,
    setPaginationURL,
  } = React.useContext(PackageContext);
  const { snackbarUI } = useUI();
  const packageService = new PackageService();

  const styles = DashboardStyles();

  const handleSearch = async (e) => {
    const {value} = e.target;
    if (!value) {
      getPackages({});
    } else {
      try {
        packageService.setAccessToken(token);
        const response = await packageService.list('?page=1&searchBy=' + value);
        if (!_isUndefined(response.data?.firstPageUrl)) {
          setPaginationURL(_split(response.data?.firstPageUrl, '?')[1]);
        }
        setRowsState({
          ...rowsState,
          rows: response.data.data,
          rowCount: Number(response.data.total),
          page: Number(response.data.currentPage - 1),
        });
      } catch (error) {
        AppHelper.checkError(error, snackbarUI);
      }
    }
  };

  const debouncedSubmit = _debounce(handleSearch, 500);

  return (
    <Box sx={styles.filtersBox}>
      <Typography>Filter by:</Typography>
      <TextField
        variant='outlined'
        size='small'
        InputProps={{ startAdornment: <SearchIcon /> }}
        placeholder='Search user...'
        onChange={debouncedSubmit}
        style={{ width: '30%' }}
      />
    </Box>
  );
};

export default PackageFilter;

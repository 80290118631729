import { useState } from 'react';

import PropTypes from 'prop-types';

import { Alert, Typography, Button, Dialog } from '@mui/material';
import {
  BtnActionsDlgAsk, ContentSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import ProvidersService from '../../core/services/providers.service';

const DeleteAccountDialog = ({
  data,
  openDialog,
  setOpenDialog,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);

  const providerService = new ProvidersService();

  const removeAccount = async (id) => {
    try {
      setLoading(true);
      await providerService.dropProvider(id)
      .then(() => {
        setOpenDialog(false);
        return onSuccess();
      }).finally(() => {
        setLoading(false);
      });
    } catch (error) {
      setErrror(true);
      console.log(error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <ContentSectionDialog>
          <Typography>
            {`Are you sure to remove ${data?.name} for this account?`}
          </Typography>
        </ContentSectionDialog>
        {error && <Alert severity="warning">Fail to remove, try again.</Alert>}
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant='outlined'
            color='primary'
            onClick={() => {
              removeAccount(data.agreementId);
            }}
          >
            Remove
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default DeleteAccountDialog;

DeleteAccountDialog.propTypes = {
  data: PropTypes.shape({
    agreementId: PropTypes.number,
    name: PropTypes.string,
  }),
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

import AppService from './AppService';

class ReportsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/reports');
  }

  getRiders(riderType){
    return this.http.get(`${this.path}/agreements/${riderType}`, { responseType: 'blob' });
  }

  getCustomersReport(){
    return this.http.get(`${this.path}/customers`, { responseType: 'blob' });
  }

  getStatementReport(month, year){
    return this.http.get(`${this.path}/statement?month=${month}&year=${year}`, { responseType: 'blob' });
  }

}

export default ReportsService;

import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { theme } from '../../../theme';

export const BoxLine = styled(Box)({
  width: "100%",
  borderBottom: "1px solid #EAE9E9",
  margin: "1em 0",
});

export const BoxTicket = styled(Box)({
  backgroundColor: theme.palette.primary.color,
  border: '1px solid #f5f5f5',
  borderRadius: '4px',
  margin: '2em 0',
  boxShadow: '10px 10px 15px rgb(0 0 0 / 3%)',
});

export const BoxTicketMain = styled(BoxTicket)({
  padding: '25px 20px',
});

export const BoxTitle = styled(Typography)({
  color: '#010101',
  fontSize: '48px',
  fontFamily: 'Bebas Neue',
  fontWeight: 'bold',
});

export const ButtonReturn = styled(Button)({
  color: theme.palette.primary.color,
  backgroundColor: theme.palette.primary.main,
  marginTop: '30px',
  marginBottom: '10px',
  borderRadius: '20px',
  padding: '5px 15px',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
});

export const ButtonSave = styled(ButtonReturn)({
  margin: '0',
  height: '0',
  padding: '20px 25px',
  fontWeight: 'bold',
  fontsize: '14px',
  letterSpacing: '1px'
});

export const ContainerAccount = styled(Container)({
  maxWidth: { xs: '540px', md: '960px', lg: '1140px' },
});

export const Label = styled(Typography)({
  color: theme.palette.body.main,
  fontFamily: 'Montserrat',
});


export const LabelTitle = styled(Label)({
  fontSize: '18px',
  fontWeight: '600',
});

export const LabelSubtitle = styled(Label)({
  fontSize: '14px',
});

export const LabelText = styled(Label)({
  fontSize: '18px',
  fontWeight: '600',
});

export const BoxAddress = styled(Box)({
  '& .MuiTypography-root': {
    paddingRight: '2em'
  }
});

export const TitlePage = styled(Typography)({
    fontFamily: 'Bebas Neue',
    fontSize: '48px',
    fontWeight: 'bold'
});

export const gridItemStyles = {
  padding: '13px 0px',
  borderRadius: '7px 7px 0px 0px',
  borderTop: 'solid 1px',
  borderLeft: 'solid 1px',
  borderRight: 'solid 1px',
  textAlign: 'center',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export const wrapperContentTypeMusic = {
  borderLeft: 'solid 1px', 
  borderRight: 'solid 1px', 
  borderBottom: 'solid 1px', 
  padding: '0px 50px', 
  borderRadius: '0px 0px 7px 7px'
};
import {
 BtnActionsDlgAsk,
 ContentSectionDialog,
} from "../../../assets/styles/admin-dashboard.dialog.styles";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";
import {Button, Typography} from "@mui/material";
import InputField from "../../../../core/widgets/input.widget";
import Grid from "@mui/material/Grid";
import {DialogComponent} from "../../../../core/layouts/widgets/header/header.styles";
import * as Yup from "yup";

const PackageSummaryDialog = ({setOpenDialog, openDialog, updateSummary, packageId}) => {

 const handleDialogClose = () => {
  setOpenDialog(false);
 };

 const initialValues = {rate: ''};
 const validationSchema = Yup.object().shape({
  rate: Yup.string()
    .required(`Annual Rate  is required`)
    .matches(/^[0-9]+(\.[0-9]{1,2})?$/, 'Must be a number')
 });

 return (
   <DialogComponent open={openDialog} onClose={handleDialogClose}>
    <ContentSectionDialog>
     <Formik
       initialValues={initialValues}
       validationSchema={validationSchema}
       onSubmit={(values) => updateSummary(values, packageId)}
     >
      {() => {
       return (
         <Form>
          <Grid container spacing={1}>
           <Grid item xs={12}>
            <Typography className='label' sx={{marginTop: '15px'}}>Annual Rate</Typography>
            <InputField
              name={'rate'}
              label={'Annual Rate'}
              sx={{marginBottom: '0px'}}
            />
           </Grid>
          </Grid>
          <BtnActionsDlgAsk>
           <Button
             variant='outlined'
             color='secondary'
             onClick={() => {
              handleDialogClose();
              setOpenDialog(false);
             }}
           >
            Cancel
           </Button>
           <Button variant='outlined' color='primary' type='submit'>
            Save
           </Button>
          </BtnActionsDlgAsk>
         </Form>
       )}
      }
     </Formik>
    </ContentSectionDialog>
   </DialogComponent>
 )
}

export default PackageSummaryDialog;

PackageSummaryDialog.propTypes = {
 openDialog: PropTypes.bool,
 setOpenDialog: PropTypes.func,
 providersValues: PropTypes.array,
 categoriesValues: PropTypes.array,
 updateSummary: PropTypes.func,
 packageId: PropTypes.string,
};

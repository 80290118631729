/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import {
  BoxTicketMain, BoxTitle,
  ButtonSave,
  LabelSubtitle,
  wrapperContentTypeMusic,
  LabelTitle,
} from "../../assets/styles/annual-rates.style";
import SettingsService from "../../../core/services/settings.service";
import AppHelper from "../../../core/helpers/AppHelper.js";
import { useSelector } from "react-redux";
import { useUI } from "../../../core/context/ui.js";
import LoaderWidget from "../../../core/widgets/loader/loader.widget.js";
import { Form, Formik } from "formik";
import InputField from "../../../core/widgets/input.widget";
import * as Yup from 'yup';

const BackgroundMusic = ({props}) => {

  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;
  const { snackbarUI } = useUI();
  const [annualRatesData, setAnnualRatesData] = useState({});

  const settingsService = new SettingsService();

  const getAnnualRates = async () => {
    try {
      setLoader(true);
      settingsService.setAccessToken(accessToken)
      const response = await settingsService.getAnnualRatesBgMusic();
      setAnnualRatesData(response.data)
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getAnnualRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { bmi, sesac, ascap, fee, gmr } = annualRatesData;

  const initialValues = {
    bmiAnnual: bmi?.annual,
    sesacAnnual: sesac?.annual,
    ascapAnnual_1_500: ascap?.rangeA?.rates?.annual,
    ascapAnnual_501_1000: ascap?.rangeB?.rates?.annual,
    ascapAnnual_1001_1500: ascap?.rangeC?.rates?.annual,
    gmrRate: gmr?.annual,
    processing: fee?.processing,
    rightManagement: fee?.rightManagement,
  }

  const validationSchema = Yup.object().shape({
    bmiAnnual: Yup.string().required("Required"),
    sesacAnnual: Yup.string().required("Required"),
    ascapAnnual_1_500: Yup.string().required("Required"),
    ascapAnnual_501_1000: Yup.string().required("Required"),
    ascapAnnual_1001_1500: Yup.string().required("Required"),
    gmrRate: Yup.string().required("Required"),
    processing: Yup.string().required("Required"),
    rightManagement: Yup.string().required("Required"),
  });

  const onSubmitBMI = async (values) => {
    const data = {
      bmi: {
        annual: values.bmiAnnual
      }
    };
    setLoader(true);
    try {
      settingsService.setAccessToken(accessToken);
      await settingsService.updateAnnualRatesBgMusic(data);
      props.setOpenDialog(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  };

  const onSubmitGmr = async (values) => {
    const data = {
      gmr: {
        annual: values.gmrRate
      }
    };
    setLoader(true);
    try {
      settingsService.setAccessToken(accessToken);
      await settingsService.updateAnnualRatesBgMusic(data);
      props.setOpenDialog(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  };

  const onSubmitSESAC = async (values) => {
    const data = {
      sesac: { annual: values.sesacAnnual }
    };
    setLoader(true);
    try {
      settingsService.setAccessToken(accessToken);
      await settingsService.updateAnnualRatesBgMusic(data);
      props.setOpenDialog(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  };

  const onSubmitASCAP = async (values) => {
    const data = {
      ascap: {
        rangeA: { rates: { annual: values.ascapAnnual_1_500 } },
        rangeB: { rates: { annual: values.ascapAnnual_501_1000 } },
        rangeC: { rates: { annual: values.ascapAnnual_1001_1500 } }
      }
    };
    setLoader(true);
    try {
      settingsService.setAccessToken(accessToken);
      await settingsService.updateAnnualRatesBgMusic(data);
      props.setOpenDialog(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  };

  const onSubmitFee = async (values) => {
    const data = {
      fee: {
        processing: values.processing,
        right_management: values.rightManagement
    }
    };
    setLoader(true);
    try {
      settingsService.setAccessToken(accessToken);
      await settingsService.updateAnnualRatesBgMusic(data);
      props.setOpenDialog(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  };

  return (
    <Grid container>

      <Grid item md={12} sx={wrapperContentTypeMusic}>
        <BoxTicketMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitBMI}
            enableReinitialize={true}
          >
            {(props) => {
              // eslint-disable-next-line no-unused-vars
              const { values, handleChange } = props
              return (
                <Form id={1} component="form" style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <BoxTitle>BMI</BoxTitle>
                    <ButtonSave color='primary' type='submit'>Save Changes</ButtonSave>
                  </Box>
                  <Grid container sx={{ marginTop: "2em" }}>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ paddingRight: "2em" }}>
                            <LabelTitle>Annual Rate</LabelTitle>
                        </Box>
                        <InputField
                          name="bmiAnnual"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )
            }
            }
          </Formik>
        </BoxTicketMain>
        <BoxTicketMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitSESAC}
            enableReinitialize={true}
          >
            <Form id={2} component="form" style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BoxTitle>SESAC</BoxTitle>
                <ButtonSave color='primary' type='submit'>Save Changes</ButtonSave>
              </Box>
              <Grid container sx={{ marginTop: "2em" }}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ paddingRight: "2em" }}>
                      <LabelTitle>Annual Rate</LabelTitle>
                    </Box>
                    <InputField
                      name="sesacAnnual"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </BoxTicketMain>
        <BoxTicketMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitASCAP}
            enableReinitialize={true}
          >
            <Form id={3} component="form" style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BoxTitle>ASCAP</BoxTitle>
                <ButtonSave color='primary' type='submit'>Save Changes</ButtonSave>
              </Box>
              <Grid container sx={{ marginTop: "2em" }}>
                <Grid item xs={12}>
                  <Box sx={{marginBottom: '50px'}}>
                    <LabelTitle>Square Footage</LabelTitle>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelSubtitle>Up to 3,750 Sq. Ft.</LabelSubtitle>
                    <InputField
                      name="ascapAnnual_1_500"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelSubtitle>3,751 - 10,000 Sq. Ft.</LabelSubtitle>
                    <InputField
                      name="ascapAnnual_501_1000"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <LabelSubtitle>10,000+ Sq. Ft.</LabelSubtitle>
                    <InputField
                      name="ascapAnnual_1001_1500"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </BoxTicketMain>
        <BoxTicketMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitGmr}
            enableReinitialize={true}
          >
            <Form id={3} component="form" style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BoxTitle>GMR</BoxTitle>
                <ButtonSave color='primary' type='submit'>Save Changes</ButtonSave>
              </Box>
              <Grid container sx={{ marginTop: "2em" }}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ paddingRight: "2em" }}>
                      <LabelTitle>Rate</LabelTitle>
                    </Box>
                    <InputField
                      name="gmrRate"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </BoxTicketMain>
      </Grid>

      <Grid item md={12}>
        <BoxTicketMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitFee}
            enableReinitialize={true}
          >
            <Form id={4} component="form" style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BoxTitle>Processing Fee</BoxTitle>
                <ButtonSave color='primary' type='submit'>Save Changes</ButtonSave>
              </Box>
              <Grid container sx={{ marginTop: "2em" }}>
                <Grid item xs={8}>
                  <Box sx={{ display: "flex" }}>
                    <InputField
                      fullWidth
                      name="processing"
                      label="%"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </BoxTicketMain>
        <BoxTicketMain>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitFee}
            enableReinitialize={true}
          >
            <Form id={5} component="form" style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BoxTitle>Right Managements Fee</BoxTitle>
                <ButtonSave color='primary' type='submit'>Save Changes</ButtonSave>
              </Box>
              <Grid container sx={{ marginTop: "2em" }}>
                <Grid item xs={8}>
                  <Box sx={{ display: "flex" }}>
                    <InputField
                      fullWidth
                      name="rightManagement"
                      label="%"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </BoxTicketMain>
      </Grid>

      <LoaderWidget loader={loader} />
    </Grid>
  )
}

export default BackgroundMusic;

import {createContext, useEffect, useState} from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import Paper from "@mui/material/Paper";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {useSelector} from "react-redux";

import AppHelper from "../../../core/helpers/AppHelper";
import {useUI} from "../../../core/context/ui";
import { DashboardStyles } from "../../assets/styles/admin-dashboard.styles.js";
import CustomNoRowsOverlay from "../../../assets/CustomNoRowsOverlay";
import PackageService from "../../../core/services/package.service";
import LoaderWidget from "../../../core/widgets/loader/loader.widget";
import PackageFilter from "./package-filtering.table";
import {PackagePagination} from "./package-pagintation.table";
import {CheckCircle} from "@mui/icons-material";
import { theme } from '../../../theme';
import {isUndefined as _isUndefined, isNaN as _isNaN } from 'lodash';
import {useNavigate} from "react-router-dom";
import {sx} from "./package-page.styles";
import DialogPackageDelete from "./package-delete.dialog";

const packageService = new PackageService();
const PackageTable = () => {
  const {snackbarUI} = useUI();
  const [loader, setLoader] = useState(false);
  const {user} = useSelector((state) => state.user);
  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    page: location?.state?.page ?? 1,
  });
  const [sortModel, setSortModel] = useState({
    field: '',
    sort: '',
  });
  const [openDlgPkgDelete , setOpenDlgPkgDelete ] = useState(false);
  const [packageId, setPackageId] = useState('');

  const accessToken = user?.token;
  const navigate = useNavigate();

  const styles = DashboardStyles();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'left',
      hide: 'true',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Package Name',
      align: 'left',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'providers.ascap',
      headerName: 'ASCAP',
      flex: 0.4,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {params?.row?.providers.ascap ? (
            <CheckCircle sx={{ fill: theme.palette.primary.main }} />
          ) : (
            <CheckCircle sx={{ fill: '#eae9e9' }} />
          )}
        </>
      ),
    },
    {
      field: 'providers.bmi',
      headerName: 'BMI',
      flex: 0.4,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {params?.row?.providers.bmi ? (
            <CheckCircle sx={{ fill: theme.palette.primary.main }} />
          ) : (
            <CheckCircle sx={{ fill: '#eae9e9' }} />
          )}
        </>
      ),
    },
    {
      field: 'providers.sesac',
      headerName: 'SESAC',
      flex: 0.4,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {params?.row?.providers.sesac ? (
            <CheckCircle sx={{ fill: theme.palette.primary.main }} />
          ) : (
            <CheckCircle sx={{ fill: '#eae9e9' }} />
          )}
        </>
      ),
    },
    {
      field: 'providers.gmr',
      headerName: 'GMR',
      flex: 0.4,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {params?.row?.providers.gmr ? (
            <CheckCircle sx={{ fill: theme.palette.primary.main }} />
          ) : (
            <CheckCircle sx={{ fill: '#eae9e9' }} />
          )}
        </>
      ),
    },
    {
      field: 'monthly',
      headerName: 'RATE',
      flex: 0.5,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>{params?.value}/month</>
      )
    },
    {
      field: 'totalSubscribers',
      headerName: 'Total Subscribers',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'code',
      headerName: 'Group Code',
      type: 'number',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.2,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'actionHeader',
      getActions: (params) => [
        <GridActionsCellItem
          label='Edit'
          showInMenu
          key={0}
          sx={sx}
          onClick={()=>{navigate('/admin/package/edit/' + params?.row.id)}}
        />,
        params?.row?.totalSubscribers === 0 ?
          <GridActionsCellItem
            label='Delete'
            showInMenu
            key={1}
            sx={sx}
            onClick={() => {
              setPackageId(params?.row?.id);
              setOpenDlgPkgDelete(true);
            }}
          />
          : <></>
        ,
      ],
    },
  ];

  const getPackages = async (...params) => {
    const page = _isUndefined(params[0]?.page) || _isNaN(params[0]?.page) ? '1' : params[0]?.page;
    try {
      setLoader(true);
      packageService.setAccessToken(accessToken);
      const r1 = await packageService.list('?page='+ page );
      setRowsState({
        ...rowsState,
        rows: r1.data.data,
        rowCount: Number(r1.data.recordsTotal),
        page:page === 0 ? 0 : page ? page - 1 : 0,
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      AppHelper.checkError(error, snackbarUI);
    }
  };

  const handleSortModelChange = (sortModel) => {
    if (_isEmpty(sortModel)) {
      getPackages({});
      setSortModel({ field: '', sort: '' });
    } else if (sortModel[0].sort === 'asc') {
      const { field, sort } = sortModel[0];
      setSortModel({ field, sort });
      getPackages({
        pageSize: rowsState.pageSize,
        page: rowsState.page,
        sort: field,
      });
    } else if (sortModel[0].sort === 'desc') {
      const { field, sort } = sortModel[0];
      setSortModel({ field, sort });
      getPackages({
        pageSize: rowsState.pageSize,
        page: rowsState.page,
        sort: `-${field}`,
      });
    }
  };

  const handleDialogClose = () => {
    setOpenDlgPkgDelete(false);
  }

  const deletePackage = async (id) => {
    try {
      setLoader(true);
      setOpenDlgPkgDelete(false);
      packageService.setAccessToken(accessToken);
      await packageService.delete(id);
      getPackages();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      AppHelper.checkError(error, snackbarUI);
    }
  };

  useEffect(() => {
    (async function init() {
      await getPackages({
        order: sortModel.field,
        orderDir: sortModel.sort,
        page: rowsState.page
      });
    })();
    // eslint-disable-next-line
  }, [accessToken]);

  const props = {
    accessToken,
    rowsState,
    sortModel,
    getPackages,
    setRowsState,
  };

  return (
    <>
      <Paper elevation={4} sx={styles.wrapperTable}>
        <PackageContext.Provider value={props}>
          <PackageFilter/>
          <DataGrid
            columns={columns}
            {...rowsState}
            headerHeight={56}
            rowHeight={56}
            components={{
              Pagination: PackagePagination,
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay: CustomNoRowsOverlay,
            }}
            pagination
            paginationMode='server'
            disableSelectionOnClick
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            checkboxSelection={false}
            sx={styles.tableGrid}
          />
        </PackageContext.Provider>
      </Paper>
      {openDlgPkgDelete && (
        <DialogPackageDelete
          openDialog={openDlgPkgDelete}
          packageId={packageId}
          handleDialogClose={handleDialogClose}
          deletePackage={deletePackage}
        />
      )}
      <LoaderWidget loader={loader}/>
    </>
  );
}

export const PackageContext = createContext({});
export default PackageTable;

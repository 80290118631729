import { createContext, useEffect, useState } from 'react';

import { isEmpty as _isEmpty, split as _split } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { FormControl, MenuItem, Paper, Select } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import CustomNoRowsOverlay from '../../assets/CustomNoRowsOverlay';
import UserService from '../../core/services/admin.service';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import { theme } from '../../theme';
import { DashboardStyles } from '../assets/styles/admin-dashboard.styles.js';
import DialogAskDelete from './ask-delete.dialog';
import DialogCreateAdmin from './create-admin.dialog';
import CustomFilter from './custom/custom-filtering.table';
import { CustomPagination } from './custom/custom-pagintation.table';

const sx = {
  '&:hover': {
    transition: 'all 0.2s',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    '& .MuiListItemIcon-root': {
      transition: 'all 0.2s',
      color: theme.palette.primary.color,
    },
  },
};

const AdminDashboardTable = ({ setOpenDlg, openDlg }) => {
  const { user } = useSelector((state) => state.user);
  const { token } = user;

  const styles = DashboardStyles();

  const userService = new UserService();

  const [rowsState, setRowsState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    page: 0,
  });

  const [paginationURL, setPaginationURL] = useState('');
  const [sortModel, setSortModel] = useState({
    field: '',
    sort: '',
  });
  const [selectedStatus, setSelectedStatus] = useState('');

  const [loader, setLoader] = useState(false);
  const [openDlgAskDelete, setOpenDlgAskDelete] = useState(false);
  const [openDlgEdit, setOpenDlgEdit] = useState(false);
  const [openDialogDlt, setOpenDialogDlt] = useState(false);
  const [adminID, setAdminID] = useState('');

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      align: 'left',
      hide: 'true',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'fullName',
      headerName: 'Admin Name',
      align: 'left',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: 'Admin Email',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      cellClassName: 'status_cell',
      headerClassName: 'status_header',
      renderCell: (params) => {
        return (
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              variant='standard'
              IconComponent={ExpandMoreRoundedIcon}
              MenuProps={{
                PaperProps: {
                  elevation: 2,
                  sx: [styles.paper],
                },
              }}
              autoWidth
              value={params?.row?.status}
              sx={[
                styles.select,
                params.row.status === 'active' ? styles.statusActive : styles.statusInactive,
              ]}
              onChange={(e) => {
                updateStatus(params.row.id, e);
              }}
            >
              <MenuItem value={'active'}>Active</MenuItem>
              <MenuItem value={'inactive'}>Inactive</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.2,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerClassName: 'actionHeader',
      getActions: (params) => [
        <GridActionsCellItem
          label='Edit'
          onClick={() => {
            setOpenDlg(true);
            setAdminID(params.row.id);
            setOpenDlgEdit(true);
          }}
          showInMenu
          key={0}
          sx={sx}
        />,
        <GridActionsCellItem
          label='Delete'
          showInMenu
          key={1}
          sx={sx}
          onClick={() => {
            setOpenDialogDlt(!openDialogDlt);
            setOpenDlgAskDelete(true);
            setAdminID(params.row.id);
          }}
        />,
      ],
    },
  ];

  const getUsers = async (...params) => {
    const page = params[0]?.page;
    try {
      setLoader(true);
      userService.setAccessToken(token);
      const response = await userService.getUsers(params[0]);
      setPaginationURL(_split(response.data.firstPageUrl, '?')[1]);
      setRowsState({
        ...rowsState,
        rows: response.data.data,
        rowCount: Number(response.data.total),
        page: page === 0 ? 0 : page ? page - 1 : 0,
      });
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const updateStatus = async (id, e) => {
    try {
      setLoader(true);
      userService.setAccessToken(token);
      await userService.updateStatus(id, e.target.value);
      setTimeout(() => {
        getUsers({});
      }, 500);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const props = {
    rowsState,
    token,
    paginationURL,
    sortModel,
    selectedStatus,
    setRowsState,
    setLoader,
    setPaginationURL,
    setSelectedStatus,
    getUsers,
  };

  useEffect(() => {
    getUsers({
      pageSize: rowsState.pageSize,
      page: rowsState.page,
      order: sortModel.sort,
      status: selectedStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortModelChange = (sortModel) => {
    if (_isEmpty(sortModel)) {
      getUsers({});
      setSortModel({ field: '', sort: '' });
    } else if (sortModel[0].sort === 'asc') {
      const { field, sort } = sortModel[0];
      setSortModel({ field, sort });
      getUsers({
        pageSize: rowsState.pageSize,
        page: rowsState.page,
        sort: field,
        status: selectedStatus,
      });
    } else if (sortModel[0].sort === 'desc') {
      const { field, sort } = sortModel[0];
      setSortModel({ field, sort });
      getUsers({
        pageSize: rowsState.pageSize,
        page: rowsState.page,
        sort: `-${field}`,
        status: selectedStatus,
      });
    }
  };

  return (
    <>
      <Paper elevation={4} sx={styles.wrapperTable}>
        <DataTable.Provider value={props}>
          <CustomFilter />
          <DataGrid
            columns={columns}
            {...rowsState}
            headerHeight={56}
            rowHeight={56}
            components={{
              Pagination: CustomPagination,
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay: CustomNoRowsOverlay,
            }}
            pagination
            paginationMode='server'
            disableSelectionOnClick
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            checkboxSelection={false}
            sx={styles.tableGrid}
          />
        </DataTable.Provider>
      </Paper>
      {openDialogDlt && (
        <DialogAskDelete
          openDialog={openDlgAskDelete}
          setOpenDialog={setOpenDlgAskDelete}
          getUsers={getUsers}
          adminID={adminID}
          setLoader={setLoader}
          setOpenDialogDlt={setOpenDialogDlt}
        />
      )}
      {openDlg && (
        <DialogCreateAdmin
          openDialog={openDlgEdit}
          setOpenDialog={setOpenDlgEdit}
          getUsers={getUsers}
          title='Edit Admin'
          adminID={adminID}
          setOpenDlg={setOpenDlg}
        />
      )}
      {loader && <LoaderWidget loader={loader}></LoaderWidget>}
    </>
  );
};

export default AdminDashboardTable;
export const DataTable = createContext({});

AdminDashboardTable.propTypes = {
  setOpenDlg: PropTypes.func,
  openDlg: PropTypes.bool,
};

import { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, Dialog } from '@mui/material';

import UserService from '../../core/services/admin.service';
import {
  BtnActionsDlgAsk, ContentSectionDialog, TitleSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import DialogSuccessfullDelete from './dialogSuccessfullDelete';

const DialogAskDelete = ({
  openDialog,
  setOpenDialog,
  getUsers,
  adminID,
  setLoader,
  setOpenDialogDlt,
}) => {
  const [openDlgSuccessDelete, setOpenDlgSuccessDelete] = useState(false);

  const { user } = useSelector((state) => state.user);
  const { token } = user;

  const userService = new UserService();

  const deleteUser = async (id) => {
    try {
      setLoader(true);
      setOpenDialog(false);
      userService.setAccessToken(token);
      await userService.deleteUser(id);
      setOpenDialogDlt(false);
      setTimeout(() => {
        setOpenDlgSuccessDelete(true);
        getUsers({});
      }, 500);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <TitleSectionDialog>Wait!</TitleSectionDialog>
        <ContentSectionDialog>Are you sure you want to delete this account?</ContentSectionDialog>
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
              setOpenDialogDlt(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              deleteUser(adminID);
            }}
          >
            Delete
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
      {openDlgSuccessDelete && (
        <DialogSuccessfullDelete
          openDlgSuccessDelete={openDlgSuccessDelete}
          setOpenDlgSuccessDelete={setOpenDlgSuccessDelete}
        />
      )}
    </>
  );
};

export default DialogAskDelete;

DialogAskDelete.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  id: PropTypes.string,
  handleClose: PropTypes.func,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  getUsers: PropTypes.func,
  adminID: PropTypes.number,
  setLoader: PropTypes.func,
  setOpenDialogDlt: PropTypes.func,
};

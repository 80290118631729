import customerModel from "./customer.model";

const {
	formField: {
		fullName,
		email,
		phoneNumber,
		title,
		password,
		status
	} 
} = customerModel;

export default {
	[fullName.name]: '',
	[email.name]: '',
	[phoneNumber.name]: '',
	[title.name]: '',
	[password.name]: '',
	[status.name]: '',
};

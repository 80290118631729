import * as Yup from 'yup';
import customerModel from './customer.model';

const {
	formField: {
		fullName,
		email,
		phoneNumber,
		title,
		password,
		status
	}
} = customerModel;

export default Yup.object().shape({
	[fullName.name]: Yup.string().required(fullName.requiredErrorMsg),
	[email.name]: Yup.string().email(email.requiredErrorMsg),
	[phoneNumber.name]: Yup.string().required(phoneNumber.requiredErrorMsg)
	// eslint-disable-next-line no-useless-escape
	.matches('^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$', "The phone number must be this format: (123)123-1234")
  .typeError('you must specify a number'),
	[title.name]: Yup.string().nullable(),
	[password.name]: Yup.string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    "Must contain 8 characters, one Uppercase, one Lowercase and one Number"
  ),
	[status.name]: Yup.string().required(status.requiredErrorMsg)
});

export default {
  formId: 'customerDetails',
  formField: {
    fullName: {
      name: 'fullName',
      label: 'Full Name *',
      requiredErrorMsg: 'Full name is required'
    },
    email: {
      name: 'email',
      label: 'Email *',
      requiredErrorMsg: 'Email is required'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone Number *',
      requiredErrorMsg: 'Phone Number is required'
    },
    title: {
      name: 'title',
      label: 'Title',
      requiredErrorMsg: 'Title is required'
    },
    password: {
      name: 'password',
      label: 'Create a password *',
      requiredErrorMsg: 'Password is required'
    },
		status: {
			name: 'status',
			label: 'Status *',
			requiredErrorMsg: 'Status Type is required'
		}
  }
};

import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Container, Divider, IconButton, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {
  isUndefined as _isUndefined, toString as _toString,
  size as _size, map as _map, find as _find,
  uniqueId as _uniqueId, includes as _includes,
  sumBy as _sumBy
} from "lodash";

import {DashboardStyles} from "../../../assets/styles/dashboard.styles";
import InputField from "../../../../core/widgets/input.widget";
import AppHelper from "../../../../core/helpers/AppHelper";
import {useUI} from "../../../../core/context/ui";
import ResourcesService from "../../../../core/services/resources.service";
import LoaderWidget from "../../../../core/widgets/loader/loader.widget";
import PackageService from "../../../../core/services/package.service";
import PackageProviderDialog from "./package.provider.dialog";
import {BtnActionsDlgAsk} from "../../../assets/styles/admin-dashboard.dialog.styles";
import {
  GridSummary,
  GridSummarySecundary,
  GridSummaryText,
  GridSummaryTextTitle, GridSummaryTotal, GridSummaryTotalTitle, GridTotal, MountTotal, Period
} from "../../../assets/styles/summary.styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DialogPackageDelete from "../package-delete.dialog";
import PackageSummaryDialog from "./package.summary.dialog";

const resourcesService = new ResourcesService();
const packageService = new PackageService();

const PackageCreatePage = () => {
  const {snackbarUI} = useUI();
  const [loader, setLoader] = useState(false);
  const {user} = useSelector((state) => state.user);
  const accessToken = user?.token;
  const [providersValues, setProvidersValues] = useState([]);
  const [categoriesValues, setCategoriesValues] = useState([]);
  const [total, setTotal] = useState({annual: 0, monthly: 0});
  const [openDlg, setOpenDlg] = useState(false);
  const [openDlgDelete, setOpenDlgDelete] = useState(false);
  const [openDlgSummary, setOpenDlgSummary] = useState(false);
  const [providerId, setProviderId] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const formikRef = useRef();
  const {packageId} = useParams();

  const [initialValues, setInitialValues] = useState({
    name: '',
    code: '',
    providers: []
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    providers: Yup.array().min(1, 'You should select at least 1 provider')
      .of(Yup.object().required()).required(`Provider Type is required`),
  });

  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      packageService.setAccessToken(accessToken);
      packageId ? await packageService.update(values, packageId)
        : await packageService.create(values);
      setLoader(false);
      navigate('/admin/package');
    } catch (error) {
      setLoader(false);
    }
  }

  const handleProvider = (values) => {
    try {
      setLoader(true);
      if (formikRef.current) {
        const option = formikRef.current.values.providers ?? [];
        // Valid
        const valid = option.find((e) => _includes(values.providers, _toString(e.id)) && e.category_id === values.isBgMusic);
        if (!_isUndefined(valid)) {
          setMessage('Provider and category already exist');
        } else {
          const count = _size(values.providers);
          _map(values.providers, function (e) {
            const prv = _find(providersValues, function (o) {
              return o.value === e;
            });
            const cat = _find(categoriesValues, function (o) {
              return o.value === values.isBgMusic;
            });
            option.push({
              key: _uniqueId(),
              id: e,
              name: prv['key'],
              category_id: values.isBgMusic,
              category_name: cat['key'],
              annual: (parseFloat(values.rate) / count).toFixed(2),
              monthly: ((parseFloat(values.rate) / count) / 12).toFixed(2),
            });
          });

          updateTotal(option);
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }

  const processProvider = () => {
    setMessage('');
    setOpenDlg(true);
  }

  const handleDialogClose = () => {
    setOpenDlgDelete(false);
  }


  const getListTypePackages = async () => {
    try {
      setLoader(true);
      resourcesService.setAccessToken(accessToken);
      const r1 = await resourcesService.list('?type=packages');
      if (!_isUndefined(r1.data.packages)) {
        const prov = r1.data.packages.providers.map(
          (item) => ({key: item.name, value: _toString(item.id)})
        );
        const cat = r1.data.packages.categories.map(
          (item) => ({key: item.name, value: _toString(item.id)})
        );
        setProvidersValues(prov);
        setCategoriesValues(cat);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const getOnePackage = async (id) => {
    try {
      setLoader(true);
      packageService.setAccessToken(accessToken);
      const r1 = await packageService.read(id);
      const prov = r1.data.provider.map(
        (item) => ({
          key: _uniqueId(),
          id: _toString(item.id),
          name: item.name,
          category_id: item.packageProvider?.categoryId,
          category_name: item.packageProvider?.category.name,
          annual: item.packageProvider?.annual,
          monthly: item.packageProvider?.monthly
        })
      );

      setIsEdit(r1.data?.totalSubscribers > 0 ? true : false);
      setTotal({annual: r1.data?.annual, monthly: r1.data?.monthly});
      setInitialValues({name: r1.data.name, code: r1.data.code, providers: prov});

      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const handleDelete = (key) => {
    if (formikRef.current) {
      const option = formikRef.current.values.providers ?? [];
      const arr = option.filter(function (item) {
        return item.key !== _toString(key)
      });

      updateTotal(arr);
    }
  }

  const updateSummary = (values) => {

    if (formikRef.current) {
      const option = formikRef.current.values.providers ?? [];

      const arr = option.map(obj => obj.key === providerId ?
        {...obj, annual: parseFloat(values.rate), monthly: (parseFloat(values.rate) / 12).toFixed(2)} : obj);

      updateTotal(arr);
    }
  }

  const updateTotal = (option) => {
    if (formikRef.current) {
      const annual = _sumBy(option, function (o) {
        return parseFloat(o.annual);
      });
      const monthly = _sumBy(option, function (o) {
        return parseFloat(o.monthly);
      });

      setTotal({
        annual: parseFloat(annual).toFixed(2),
        monthly: parseFloat(monthly).toFixed(2)
      });

      formikRef.current.setFieldValue(
        'providers', option
      );

      setMessage('');
      setAnchorEl(null);
      setOpenDlg(false);
      setOpenDlgDelete(false);
      setOpenDlgSummary(false);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async function init() {
      await getListTypePackages();
    })();
    // eslint-disable-next-line
  }, [accessToken]);

  useEffect(() => {
    (async function init() {
      if (packageId) {
        await getOnePackage(packageId);
      } else {
        setIsEdit(false);
      }
    })();
    // eslint-disable-next-line
  }, [packageId]);

  const styles = DashboardStyles();
  return (
    <>
      <Container style={{maxWidth: '95%'}}>
        <Box sx={styles.titleWrap}>
          <Box>
            <Typography variant='h1' sx={styles.title}>
              { packageId ? 'Edit Package' : 'Package creation'}
            </Typography>
          </Box>
        </Box>
        <Paper elevation={4} sx={styles.wrapperTable}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize={true}
          >
            {(props) => {
              // eslint-disable-next-line
              const {values} = props;
              return (
                <Form>
                  <Grid container spacing={2} alignItems="center" pl={10} pr={10}>
                    <Grid item xs={6}>
                      <Typography className='label'>Package Name</Typography>
                      <InputField
                        name={'name'}
                        label={'Name'}
                        fullWidth
                        autoComplete='name'
                        disabled={isEdit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className='label'>Package Code</Typography>
                      <InputField
                        name={'code'}
                        label={'Code'}
                        fullWidth
                        autoComplete='code'
                        disabled={isEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {// eslint-disable-next-line
                        values.providers?.map((item, i) => {
                          return (
                            <div key={i}>
                              <GridSummary>
                                <Grid container spacing={1}>
                                  <Grid item xs={3}>
                                    <GridSummaryTextTitle>{`${item.name}`}</GridSummaryTextTitle>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <GridSummaryText>{`${item.category_name}`}</GridSummaryText>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <GridSummaryText>{`$${item.annual}`}</GridSummaryText>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <GridSummaryText>{`$${item.monthly}`}</GridSummaryText>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => {
                                        setProviderId(item.key);
                                        handleClick(e);
                                      }}
                                    >
                                      <MoreVertIcon/>
                                    </IconButton>
                                    <Menu
                                      id="long-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          maxHeight: 30 * 4.5,
                                          width: '10ch',
                                        },
                                      }}
                                    >
                                      <MenuItem key={1}
                                                onClick={() => {
                                                  setAnchorEl(null);
                                                  setOpenDlgSummary(true);
                                                }}>Edit</MenuItem>
                                      {!isEdit && (
                                        <MenuItem key={2}
                                                  onClick={() => {
                                                    setAnchorEl(null);
                                                    setOpenDlgDelete(true);
                                                  }}>Delete</MenuItem>
                                      )}
                                    </Menu>
                                  </Grid>
                                </Grid>
                              </GridSummary>
                              <Divider/>
                            </div>
                          );
                        })
                      }
                    <GridSummarySecundary/>
                    <GridSummarySecundary/>
                    { // eslint-disable-next-line
                      _size(values.providers) > 0 && (
                        <GridSummaryTotal>
                          <Grid container spacing={1}>
                            <Grid item xs={7.5}>
                              <GridSummaryTotalTitle>Total Rate</GridSummaryTotalTitle>
                            </Grid>
                            <Grid item xs={1}>
                              <GridTotal>
                                <MountTotal>${total.annual}</MountTotal>
                              </GridTotal>
                              <Period>Annual Rate</Period>
                            </Grid>
                            <Grid item xs={1.5}>
                              <GridTotal>
                                <MountTotal>${total.monthly}</MountTotal>
                              </GridTotal>
                              <Period>Monthly Rate</Period>
                            </Grid>
                            <Grid item xs={1}/>
                          </Grid>
                        </GridSummaryTotal>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    { !isEdit && (
                      <Box sx={styles.wrapBtn}>
                        <Button
                          sx={[styles.btn, styles.btnPrimaryLight, styles.downloadBtn]}
                          onClick={() => processProvider()}
                        >
                          <AddIcon/>
                          Add Product
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={3}/>
                  <Grid item xs={6}>
                    <BtnActionsDlgAsk>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                          navigate('/admin/package')
                        }}
                      >
                        Cancel
                      </Button>
                      <Button variant='contained' color='primary' type='submit'>
                        Save
                      </Button>
                    </BtnActionsDlgAsk>
                  </Grid>
                </Grid>
            </Form>
            )
            }}
          </Formik>
        </Paper>
        <LoaderWidget loader={loader}/>
        <PackageProviderDialog
          openDialog={openDlg}
          setOpenDialog={setOpenDlg}
          providersValues={providersValues}
          categoriesValues={categoriesValues}
          handleProvider={handleProvider}
          message={message}
        />
        { openDlgSummary &&
          <PackageSummaryDialog
            openDialog={openDlgSummary}
            setOpenDialog={setOpenDlgSummary}
            updateSummary={updateSummary}
            packageId={providerId}
          />
        }
        {openDlgDelete &&
          <DialogPackageDelete
            openDialog={openDlgDelete}
            packageId={parseInt(providerId)}
            handleDialogClose={handleDialogClose}
            deletePackage={handleDelete}
            isProvider={true}
          />
        }
      </Container>
    </>
  );
}
export default PackageCreatePage;

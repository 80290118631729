import React from 'react';

import { debounce as _debounce, split as _split } from 'lodash';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box, FormControl, InputLabel, MenuItem,
  Select, TextField, Typography,
} from '@mui/material';

import { useUI } from '../../../core/context/ui';
import AppHelper from '../../../core/helpers/AppHelper';
import UserService from '../../../core/services/admin.service';
import { DashboardStyles } from '../../assets/styles/dashboard.styles';
import { DataTable } from '../admin-dashboard.table';

const CustomFilter = () => {
  const {
    rowsState,
    setRowsState,
    sortModel,
    token,
    setSelectedStatus,
    selectedStatus,
    getUsers,
    setPaginationURL,
  } = React.useContext(DataTable);
  const { snackbarUI } = useUI();
  const userService = new UserService();

  const styles = DashboardStyles();

  const filterUsers = (e) => {
    setSelectedStatus(e.target.value);
    const status = e.target.value;
    getUsers({
      status,
      pageSize: rowsState.pageSize,
      page: rowsState.page,
      sort: sortModel.field || '',
    });
  };

  const handleSearch = async (e) => {
    const { value } = e.target;
    if (!value) {
      getUsers({});
    } else {
      try {
        userService.setAccessToken(token);
        const response = await userService.searchUsers(value);
        setPaginationURL(_split(response.data.firstPageUrl, '?')[1]);
        setRowsState({
          ...rowsState,
          rows: response.data.data,
          rowCount: Number(response.data.total),
          page: Number(response.data.currentPage - 1),
        });
      } catch (error) {
        AppHelper.checkError(error, snackbarUI);
      }
    }
  };

  const debouncedSubmit = _debounce(handleSearch, 500);

  return (
    <Box sx={styles.filtersBox}>
      <Typography>Filter by:</Typography>
      <TextField
        variant='outlined'
        size='small'
        InputProps={{ startAdornment: <SearchIcon /> }}
        placeholder='Search user...'
        onChange={debouncedSubmit}
        style={{ width: '30%' }}
      />
      <FormControl size='small' sx={{ minWidth: 200, ml: 2 }}>
        <InputLabel id='status'>Status</InputLabel>
        <Select
          labelId='status'
          id='status'
          label='Status'
          value={selectedStatus}
          onChange={(e) => filterUsers(e)}
        >
          <MenuItem value={0}>All status</MenuItem>
          <MenuItem value='active'>Active</MenuItem>
          <MenuItem value='inactive'>Inactive</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomFilter;

import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Container, IconButton } from '@mui/material';

import { logoutSuccess } from '../../../../app/features/user/userSlice';
import logo from '../../../../assets/images/logo.svg';
import { theme } from '../../../../theme';
import {
  BoxNav, Btn, Header, LinkLogo,
  NavItem,
} from './header.styles';
import Sidebar from '../sidebar/sidebar.widget';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const HeaderWidget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeSidebar, setActiveSidebar] = useState(false);

  const logout = () => {
    dispatch(logoutSuccess());
    navigate('/');
  };

  return (
    <Box position='static' style={{ background: theme.palette.body.main }}>
      <Container disableGutters sx={{ maxWidth: { md: '92%' } }}>
        <Header component='header'>
          <div>
            <IconButton 
              aria-label="menu" 
              sx={{color: 'white', marginRight: '15px'}}
              onClick={()=>{setActiveSidebar(!activeSidebar)}}
            >
              <MenuOpenIcon />
            </IconButton>
            <LinkLogo href='/admin/dashboard'>
              <Box component='img' src={logo} />
            </LinkLogo>
          </div>
          <BoxNav component='ul'>
            <NavItem component='li'>
              <Btn variant='contained' onClick={logout}>
                Logout
              </Btn>
            </NavItem>
          </BoxNav>
        </Header>
      </Container>
      {
        (activeSidebar) 
          && 
            <Sidebar 
              setActiveSidebar={setActiveSidebar} 
              activeSidebar={activeSidebar}
            />
      }
    </Box>
  );
};

export default HeaderWidget;

import { capitalize as _capitalize } from 'lodash';

import { CheckCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { theme } from '../../theme';

const statusStyle = (status) => {
  const styles = {
    height: '32px',
    display: 'flex',
    padding: '0 12px',
    alignItems: 'center',
    borderRadius: '100px',
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 'bold',
      letterSpacing: '0.1px',
      textTransform: 'capitalize',
    },
  };
  if (status === 'drop') {
    return {
      ...styles,
      backgroundColor: '#DA605B',
      color: 'whitesmoke',
    };
  }
  if (status === 'active') {
    return {
      ...styles,
      backgroundColor: 'rgba(39, 174, 96, 0.12)',
      color: 'rgba(39, 174, 96, 1)',
    };
  } else if (status === 'cancelled') {
    return {
      ...styles,
      backgroundColor: 'rgba(235, 87, 87, 0.12)',
      color: 'rgba(235, 87, 87, 1)',
    };
  } else {
    return {
      ...styles,
      backgroundColor: 'rgba(189, 189, 189, 0.2)',
      color: 'rgba(189, 189, 189, 1)',
    };
  }
};

export const columns = [
  {
    field: 'startDate',
    headerAlign: 'center',
    headerName: 'Start Date',
    flex: 0.5,
    align: 'center',
    disableColumnMenu: true,
  },
  {
    field: 'name',
    headerAlign: 'center',
    align: 'center',
    headerName: 'Gym Name',
    flex: 0.8,
    disableColumnMenu: true,
  },
  {
    field: 'city',
    headerAlign: 'center',
    align: 'center',
    headerName: 'City',
    disableColumnMenu: true,
    flex: 0.6,
    renderCell: (params) => <>{params.row.city ? params.row.city : 'N/A'}</>,
  },
  {
    field: 'email',
    headerAlign: 'center',
    headerName: 'Customer Email',
    flex: 1,
    align: 'center',
    disableColumnMenu: true,
  },
  {
    field: 'providers.ascap',
    headerAlign: 'center',
    headerName: 'ASCAP',
    align: 'center',
    flex: 0.3,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <>
        {params?.row?.providers.ascap ? (
          <CheckCircle sx={{ fill: theme.palette.primary.main }} />
        ) : (
          <CheckCircle sx={{ fill: '#eae9e9' }} />
        )}
      </>
    ),
  },
  {
    field: 'providers.bmi',
    headerAlign: 'center',
    headerName: 'BMI',
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 0.2,
    renderCell: (params) => (
      <>
        {params?.row?.providers.bmi ? (
          <CheckCircle sx={{ fill: theme.palette.primary.main }} />
        ) : (
          <CheckCircle sx={{ fill: '#eae9e9' }} />
        )}
      </>
    ),
  },
  {
    field: 'providers.sesac',
    headerAlign: 'center',
    headerName: 'SESAC',
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 0.3,
    renderCell: (params) => {
      return (
        <>
          {params?.row?.providers.sesac ? (
            <CheckCircle sx={{ fill: theme.palette.primary.main }} />
          ) : (
            <CheckCircle sx={{ fill: '#eae9e9' }} />
          )}
        </>
      );
    },
  },
  {
    field: 'providers.gmr',
    headerAlign: 'center',
    headerName: 'GMR',
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 0.3,
    renderCell: (params) => {
      return (
        <>
          {params?.row?.providers.gmr ? (
            <CheckCircle sx={{ fill: theme.palette.primary.main }} />
          ) : (
            <CheckCircle sx={{ fill: '#eae9e9' }} />
          )}
        </>
      );
    },
  },
  {
    field: 'codeManagementFee',
    headerAlign: 'center',
    headerName: 'Promo Code',
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 0.5,
    renderCell: (params) => {
      return (
        params?.row?.codeManagementFee.length > 0 ? (
         <CheckCircle sx={{ fill: theme.palette.primary.main }} />
        ) : (
          <CheckCircle sx={{ fill: '#eae9e9' }} />
        )
      );
    }
  },
  {
    field: 'package',
    headerAlign: 'center',
    headerName: 'Package',
    align: 'center',
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    flex: 0.5,
    renderCell: (params) => {
      return (
        params?.row?.package ? (
         <CheckCircle sx={{ fill: theme.palette.primary.main }} />
        ) : (
          <CheckCircle sx={{ fill: '#eae9e9' }} />
        )
      );
    }
  },
  {
    field: 'paymentStatus',
    headerAlign: 'center',
    headerName: 'Payment Status',
    flex: 0.5,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    align: 'center',
    renderCell: (params) => {
      return (
        <Box sx={statusStyle(params?.row?.paymentStatus.toLocaleLowerCase())}>
          <Typography>{_capitalize(params?.row?.paymentStatus) || 'N/A'}</Typography>
        </Box>
      );
    },
  },
];

import React, { useState } from 'react';

import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography } from '@mui/material';

import { loginStatus } from '../../app/features/appSlice';
import { loginSuccess } from '../../app/features/user/userSlice';
import AppHelper from '../../core/helpers/AppHelper';
import {
  BoxError, BoxForgotPsw, BoxForm, BoxPswLabels,
  BtnLink, BtnLogin,
} from '../../core/layouts/widgets/header/header.styles';
import AuthService from '../../core/services/auth.service';
import { loginStyles } from './login.styles';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [seePW, setSeePw] = useState(false);
  const [textError, setTextError] = useState(null);
  const [openDialogInactive, setOpenDialogInactive] = useState(false)

  const authService = new AuthService();
  const styles = loginStyles();

  const initialValues = {
    email: '',
    password: '',
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid e-mail').required('Required'),
    password: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
  });

  const showPassword = () => {
    setSeePw(!seePW);
  };

  const onSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };

    try {
      const r1 = await authService.login(data);
      const accessToken = r1.data.token;
      authService.setAccessToken(accessToken);
      const r2 = await authService.me();
      if (r2.data.status === 'inactive'){
        setOpenDialogInactive(true)
      }
      else {
        let payload = { ...r2.data, token: accessToken };
        dispatch(loginSuccess(payload));
        navigate('/admin/dashboard', { replace: true });
        dispatch(loginStatus());
      }
    } catch (error) {
      setTextError(AppHelper.getError(error));
    }
  };

  const handleDialogClose = () => {
    setOpenDialogInactive(false)
  };
  
  return (
    <>
      <Box sx={styles.bgMain}>
        <Box sx={styles.backdropBlur}>
          <Paper elevation={3} sx={styles.paperBox}>
            <Box sx={styles.welcome}>
              <Typography variant='h2'>Welcome!</Typography>
              <Typography>Sign in to continue</Typography>
            </Box>
            <BoxForm>
              <Formik
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={onSubmit}
              >
                {(props) => {
                  const { values, handleChange } = props;
                  return (
                    <Form component='form'>
                      <Box>
                        <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label' htmlFor='email'>
                            Email Address
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          value={values.email}
                          onChange={handleChange}
                          name='email'
                          variant='outlined'
                          label='Enter email address...'
                          helperText={<ErrorMessage name='email' />}
                        />
                      </Box>
                      <Box>
                        <BoxPswLabels>
                          <Typography component='label'>Password</Typography>
                          <Button
                            component='label'
                            sx={{ textTransform: 'none' }}
                            onClick={showPassword}
                          >
                            Show Password
                          </Button>
                        </BoxPswLabels>
                        <TextField
                          fullWidth
                          name='password'
                          type={seePW ? 'text' : 'password'}
                          variant='outlined'
                          label='Type your password...'
                          value={values.password}
                          onChange={handleChange}
                          helperText={<ErrorMessage name='password' />}
                        />
                        <BoxForgotPsw>
                          <Typography>Forgot Password?&nbsp;</Typography>
                          <Typography>
                            <BtnLink>Send Email</BtnLink>
                          </Typography>
                        </BoxForgotPsw>
                      </Box>
                      <Box>
                        <BtnLogin
                          type='submit'
                          variant='contained'
                          disabled={props.isSubmitting}
                          fullWidth
                        >
                          {props.isSubmitting ? 'Loading' : 'Sign In'}
                        </BtnLogin>
                      </Box>
                      {textError && <BoxError>{textError}</BoxError>}
                    </Form>
                  );
                }}
              </Formik>
            </BoxForm>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={openDialogInactive}
        onClose={handleDialogClose}
      >
        <DialogTitle>Sorry</DialogTitle>
        <DialogContent>Your account is inactive</DialogContent>
        <DialogActions>
          <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                handleDialogClose();
              }}
            >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;

Login.propTypes = {
  setOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  isSubmitting: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

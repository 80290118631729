import { serialize } from 'object-to-formdata';

import AppService from './AppService';

class UserService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/users');
  }

  getUsers({ pageSize = 10, page = 0, status = '', sort = '' }) {
    return this.http.get(
      `${this.path}?render=paginate&page=${page}&size=${pageSize}&sort=${sort}&filter[status]=${status}`
    );
  }

  createUser(data) {
    const formData = serialize(data);
    return this.http.post(`${this.path}`, formData);
  }

  readUser(id) {
    return this.http.get(`${this.path}/${id}`);
  }

  update(id, data) {
    return this.http.patch(`${this.path}/${id}`, data);
  }

  updateStatus(id, status) {
    return this.http.patch(`${this.path}/${id}?status=${status}`);
  }

  deleteUser(id) {
    return this.http.delete(`${this.path}/${id}`);
  }

  searchUsers(query, pageSize = 10) {
    return this.http.get(`${this.path}?render=paginate&searchBy=${query}&size=${pageSize}`);
  }

  pagination(url, page) {
    return this.http.get(`${this.path}?${url}&page=${page}`);
  }
}

export default UserService;

import { theme } from '../../../theme';

export const DashboardStyles = () => ({
  titleWrap: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 0 15px',
    ['@media (min-width: 992px)']: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '40px 0 36px',
    },
    '& h1': {
      color: '#010101',
      fontSize: '32px',
      margin: '5px 0 10px',
      ['@media (min-width: 992px)']: {
        fontSize: '48px',
      },
    },
  },

  adminTitleWrap: {
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    borderRadius: '4px',
    padding: '20px 15px',
  },

  title: {
    fontFamily: 'Bebas Neue',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    lineHeight: 0.9,
  },

  subTitle: {
    fontSize: '14px',
    marginBottom: 0,
    fontFamily: 'Montserrat',
  },

  wrapBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '15px',
    ['@media (min-width: 575px)']: {
      flexDirection: 'row',
    },
    '& a + a': {
      marginLeft: '12px',
    },
    '& .css-i4bv87-MuiSvgIcon-root': {
      padding: '0 5px 0 0',
    },
  },

  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.primary.color,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    border: '2px solid hsla(0, 0%, 100%, 0.2)',
    borderRadius: '2rem',
    fontSize: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '10px 17px',
    textDecoration: 'none',
  },

  btnPrimaryLight: {
    backgroundColor: 'rgba(245, 166, 35, 0.12)',
    color: theme.palette.primary.main,
    border: '2px solid hsla(0, 0%, 100%, 0)',
  },

  downloadBtn: {
    fontSize: '12px',
    padding: '11px 17px',
    maxHeight: '40px',
    margin: 0,
  },

  ico: {
    marginRight: '12px',
    maxWidth: '12px',
    maxHeight: '15px',
  },

  dashboardTableStyle: {
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    borderRadius: '4px',
    padding: '20px 15px',
    position: 'relative',
    marginBottom: '20px',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '12px',
      ['@media (min-width: 992px)']: {
        fontSize: '14px',
      },
      fontWeight: 'bold',
      verticalAlign: 'middle',
      fontFamily: 'Montserrat',
      padding: '0.75rem',
    },
  },

  dataGridStyles: {
    fontFamily: 'Montserrat',
    minHeight: 600,
    maxHeight: 650,
    borderRadius: '6px',
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '0',
    fontSize: '14px',
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
  },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeadersInner': {
      fontSize: '14px',
      '& .MuiDataGrid-columnHeader': {
        padding: '0',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '700',
      },
      '& .status_header': {
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
      },
    },
    '& .MuiDataGrid-row': {
      fontSize: { xs: '12px', md: '14px' },
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '30px',
      padding: '0 ',
    },
    '& .status_cell': {
      padding: 0,
    },
    '& .MuiDataGrid-virtualScroller': {
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(16, 16, 16, 0.5)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'grid',
      gridTemplateColumns: '1fr 215px',
      // justifyContent: 'center',
      border: 'none',
      borderTop: '1px solid #e0e0e0',
      position: 'absolute',
      width: '100%',
      paddingTop: '25px',
    },
    '& .MuiButton-root': {
      width: '100%',
      fontFamily: 'Montserrat',
      color: '#010100',
      fontSize: '12px',
      fontWeight: 'bold',
      border: '2px solid #010100',
      borderRadius: '30px',
      padding: '10px 16px',
      '& .MuiSvgIcon-root': {
        marginRight: '10px',
      },
    },
    borderBottom: 'none',
  },

  subTitleAdmin: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    marginTop: '20px',
    ['@media (min-width: 992px)']: {
      fontSize: '18px',
    },
  },

  filtersBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    marginBottom: '16px',
    '& .MuiFormControl-root': {
      marginLeft: '10px',
    },
    '& .MuiFormControl-root + .MuiFormControl-root': {
      marginLeft: '20px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      '& .MuiOutlinedInput-input': {
        padding: '8.5px 32px 8.5px 24px',
      },
    },
  },

  dashboardFooter: {
    textAlign: 'right',
  },

  wrapperTable: {
    minHeight: 800,
    padding: '30px 40px',
    marginBottom: '24px',
    backgroundColor: '#FFF',
    borderRadius: '6px',
  },
});

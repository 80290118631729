import React, { useState } from "react";
import {
  BoxRowDown,
  BoxRowTop,
  BoxTicket,
  ButtonDownload,
  LabelName,
  LabelTextTitle,
  TitleBox,
} from "../assets/styles/account.styles";
import { Box, Grid, IconButton } from "@mui/material";
import { Download, Delete, Send } from "@mui/icons-material";
import PropTypes from "prop-types";
import { EditIcon } from "../../core/widgets/MaterialUI";
import DialogEditPaymentAgreementDate from "./editPaymentAgreementDate.dialog";
import DeleteAccountDialog from "./deleteAccount.dialog";
import ResendAgreementDialog from "./resendAgreement.dialog";

const ViewProPackage = ({ data, downloadCustomerAgreement, reloadData }) => {
  const [openPmtStartDateEdit, setOpenPmtStartDateEdit] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openResendAgreementModal, setOpenResendAgreementModal] = useState(false);
  return (
    <BoxTicket key={data.name}>
      <BoxRowTop>
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <TitleBox>{data.name}</TitleBox>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 4 }}>
            {data.name === 'ASCAP' &&
              <IconButton
                onClick={() => setOpenResendAgreementModal(true)}
                size="small"
                color="primary"
              >
                <Send />
              </IconButton>}
              <IconButton
                onClick={() => setOpenRemoveModal(true)}
                color='warning'
                size='small'
              >
                <Delete />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </BoxRowTop>
      <BoxRowDown>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item xs={6}>
            <LabelTextTitle>{data.package}</LabelTextTitle>
            <LabelName>PRO License</LabelName>
          </Grid>
          <Grid item xs={6}>
            <LabelTextTitle>{data.category}</LabelTextTitle>
            <LabelName>Music Use</LabelName>
          </Grid>
        </Grid>
      </BoxRowDown>
      <BoxRowDown>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item xs={2}>
            <LabelTextTitle>${data.annualRate}</LabelTextTitle>
            <LabelName>{data.name} Annual Rate</LabelName>
          </Grid>
          <Grid item xs={2}>
            <LabelTextTitle>${data.monthlyRate}</LabelTextTitle>
            <LabelName>{data.name} Monthly Rate</LabelName>
          </Grid>
          <Grid item xs={2}>
            <LabelTextTitle>{data.paymentStartDate}</LabelTextTitle>
            <LabelName>Payment Start Date</LabelName>
          </Grid>
          <Grid item xs={2}>
            {data.agreementStartDate && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <LabelTextTitle>{data.agreementStartDate}</LabelTextTitle>
                  <IconButton
                    onClick={() => {
                      setOpenPmtStartDateEdit(true);
                    }}
                    color="primary"
                    size="small"
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </Box>

                <LabelName>Agreement Start Date</LabelName>
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            {data.agreementRenewalDate && (
              <>
                <LabelTextTitle>{data.agreementRenewalDate}</LabelTextTitle>
                <LabelName>Agreement Renewal Date</LabelName>
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            <ButtonDownload
              onClick={() => {
                downloadCustomerAgreement(data.link, data.name);
              }}
            >
              <Download />
              Agreement
            </ButtonDownload>
            {data.coverageLink && (
              <ButtonDownload
                onClick={() => {
                  downloadCustomerAgreement(
                    data.coverageLink,
                    data.name,
                    false
                  );
                }}
              >
                <Download />
                Coverage&nbsp;
              </ButtonDownload>
            )}
          </Grid>
        </Grid>
      </BoxRowDown>
      {openPmtStartDateEdit && (
        <DialogEditPaymentAgreementDate
          data={data}
          openDialog={openPmtStartDateEdit}
          setOpenDialog={setOpenPmtStartDateEdit}
          onEditSuccess={reloadData}
        />)
      }
      {
        openRemoveModal &&
        <DeleteAccountDialog
          data={data}
          openDialog={openRemoveModal}
          setOpenDialog={setOpenRemoveModal}
          onSuccess={reloadData}
        />
      }
      { openResendAgreementModal &&
        <ResendAgreementDialog
          data={data}
          openDialog={openResendAgreementModal}
          setOpenDialog={setOpenResendAgreementModal}
          onSuccess={reloadData}
        />
      }
    </BoxTicket>
  );
};

export default ViewProPackage;

ViewProPackage.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    package: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    annualRate: PropTypes.number.isRequired,
    monthlyRate: PropTypes.number.isRequired,
    paymentStartDate: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    coverageLink: PropTypes.string,
    agreementStartDate: PropTypes.string.isRequired,
    agreementRenewalDate: PropTypes.string.isRequired,
  }),
  downloadCustomerAgreement: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

/* eslint-disable react/prop-types */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import {
  ButtonReturn,
  ContainerAccount,
  TitlePage,
  gridItemStyles,
} from "../../assets/styles/annual-rates.style";
import FitnessClass from "./annual-rate-fitness-class.page";
import BackgroundMusic from "./annual-rate-background-music";

const initType = {
  fitnessClass: false,
  backgroundMusic: false
}

const AnnualRatesPage = (props) => {

  const navigate = useNavigate();
  const [activeType, setActiveType] = useState({...initType, fitnessClass: true});

  const handleChangeType = (type) => {
    setActiveType({
      ...initType,
      [type]: true
    })
  };
  
  return (
    <>
      <Container>
        <ContainerAccount>
          <ButtonReturn
            onClick={() => navigate('/admin/dashboard')}
          >
            Return to dashboard
          </ButtonReturn>
          <Box>
            <TitlePage>ANNUAL RATES</TitlePage>
          </Box>

          <Grid container>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid 
                  item 
                  xs={12} 
                  md={6} 
                  sx={gridItemStyles} 
                  style={(activeType.fitnessClass) ? {backgroundColor: '#f5a623'} : {}}
                  onClick={()=>{handleChangeType('fitnessClass')}}
                >
                  FITNESS CLASS
                </Grid>
                <Grid 
                  item 
                  xs={12} 
                  md={6} 
                  sx={gridItemStyles} 
                  style={(activeType.backgroundMusic) ? {backgroundColor: '#f5a623'} : {}}
                  onClick={()=>{handleChangeType('backgroundMusic')}}
                >
                  BACKGROUND MUSIC
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              {
                (activeType.fitnessClass)
                  ? <FitnessClass props={props}/>
                  : <BackgroundMusic props={props}/>
              }
            </Grid>
          </Grid>
        </ContainerAccount>
      </Container>
    </>
  );
};

export default AnnualRatesPage;

import AppService from './AppService';

class SettingsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/settings');
  }

  getAnnualRates(){
    return this.http.get(`${this.path}/calcs`);
  }

  updateAnnualRates(data){
    return this.http.patch(`${this.path}/calcs`, data);
  }

  getAnnualRatesBgMusic(){
    return this.http.get(`${this.path}/calcs?isBgMusic=1`);
  }

  getAnnualRatesFitnessClass(){
    return this.http.get(`${this.path}/calcs?isBgMusic=0`);
  }

  updateAnnualRatesBgMusic(data){
    return this.http.patch(`${this.path}/calcs?isBgMusic=1`, data);
  }

  updateAnnualRatesFitnessClass(data){
    return this.http.patch(`${this.path}/calcs?isBgMusic=0`, data);
  }

}

export default SettingsService;

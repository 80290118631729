import {
  Box, Button, Dialog, Link,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { theme } from '../../../../theme';

export const Header = styled(Box)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  zIndex: '2',
  '& img': {
    imageOrientation: 'from-image',
  },
});

export const LinkLogo = styled(Link)({
  width: '100%',
  maxWidth: '220px',
});

export const BoxNav = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  paddingLeft: 0,
  listStyle: 'none',
});

export const Btn = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: theme.palette.primary.color,
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  border: '2px solid rgba(255, 255, 255, 0.2)',
  borderRadius: '2rem',
  fontSize: '14px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  padding: { xs: '10px 20px', sm: '16px 40px' },
  textDecoration: 'none',
});

export const BtnWhite = styled(Box)({
  backgroundColor: theme.palette.primary.color,
  color: '#010101',
  border: '2px solid rgba(255, 255, 255, 0)',
});

export const NavItem = styled(Box)({
  marginLeft: '20px',
  boxSizing: 'border-box',
  textDecoration: 'none',
});

export const DialogComponent = styled(Dialog)({
  textAlign: 'center',
  '& .MuiPaper-root': {
    borderRadius: '10px',
    width: { xs: '500px', sm: '750px' },
  },
});

export const TitleSection = styled(Box)({
  '& h3': {
    fontFamily: 'Bebas Neue',
    fontSize: '56px',
    fontWeight: 'bold',
  },
  '& h6': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
  },
});

export const BoxForm = styled(Box)({
  textAlign: 'left',
  '& label': {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'left',
  },
});

export const BoxPswLabels = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '20px 0 10px 0',
  '& label + label': {
    color: theme.palette.primary.main,
  },
});

export const BtnLogin = styled(Button)({
  color: theme.palette.primary.color,
  backgroundColor: theme.palette.primary.main,
  marginTop: '30px',
  borderRadius: '20px',
  padding: '10px',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
});

export const BoxForgotPsw = styled(Box)({
  display: 'flex',
  marginTop: '5px',
  '& p': {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'left',
  },
});

export const AccountMsg = styled(Box)({
  display: 'flex',
  fontSize: '16px',
  justifyContent: 'center',
  marginTop: '5vh',
  '& p': {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
});

export const BtnLink = styled(Link)({
  textDecoration: 'none',
  color: theme.palette.primary.main,
});

export const BtnClose = styled(Button)({
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.color,
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
});

export const BoxError = styled(Typography)({
  textAlign: 'center',
  padding: '20px 0',
  color: theme.palette.primary.main,
  fontSize: '1.2rem',
  fontWeight: 'bold',
});

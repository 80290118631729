import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Alert, AlertTitle, Box, Button, Container, Grid, Link } from "@mui/material";
import {
  ArrowIcon,
  BoxAddress,
  BoxLine,
  BoxTicketMain,
  ButtonReturn,
  ContainerAccount,
  GridBoxTagResult,
  GridTags,
  GridResult,
  LabelClassCapacity,
  LabelName,
  LabelTextTitle,
  LinksTag,
  PaymentStatus,
  ResultTag,
  ResultTagFinal,
  Tag,
  TitleBox,
  TitleBoxBootCamp,
  TitleTag,
  statusStyles,
} from "../assets/styles/account.styles.js";
import CustomerService from "../../core/services/customer.service.js";
import AppHelper from '../../core/helpers/AppHelper.js';
import { useSelector } from 'react-redux';
import { useUI } from '../../core/context/ui.js';
import LoaderWidget from '../../core/widgets/loader/loader.widget.js';
import { filter as _filter, some as _some } from 'lodash';
import { useLocation } from 'react-router-dom';
import ViewProDefault from './viewProDefault.js';
import ViewProPackage from './viewProPackage.js';
import DialogEditAccount from './editAccount.dialog.js';
import RecalculateRatesDialog from './recalculateRate.dialog.js';

const AccountPage = () => {

  const { state } = useLocation()
  const { page } = state
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;
  const { snackbarUI } = useUI();
  const params = useParams()
  const [customderData, setCustomderData] = useState({})
  const [stripeData, setStripeData] = useState({})
  const [reloadData, setReloadData] = useState(false);
  const [isOpenEditAccountModal, setIsOpenEditAccountModal] = useState(false);
  const [isOpenRecalculateRatesModal, setIsOpenRecalculateRatesModal] = useState(false);

  const customerService = new CustomerService();

  const getOneCustomer = async (id) => {
    try {
      setLoader(true);
      customerService.setAccessToken(accessToken)
      const response = await customerService.getOneCustomer(id);
      setCustomderData(response.data)
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getOneCustomer(params.id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, reloadData]);

  const isThereASCAP = _some(_filter(customderData?.providers, {name: 'ASCAP'}))
  const ASCAPData = _filter(customderData?.providers, {name: 'ASCAP'})

  const downloadCustomerAgreement = async (link, agreementType, agreement = true ) => {
    try {
      setLoader(true);
      customerService.setAccessToken(accessToken)
      //eslint-disable-next-line no-unused-vars
      const response = await customerService.downloadCustomerAgreement(link);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = agreement ? `${agreementType}_Agreement.pdf` : `${agreementType}_coverage.pdf`;
      file.click();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const downloadDocusignFile = async (link) => {
    try {
      setLoader(true);
      customerService.setAccessToken(accessToken)
      //eslint-disable-next-line no-unused-vars
      const response = await customerService.downloadDocusignFile(link);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = 'docusign_file.pdf';
      file.click();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getStripeAccount = async (id) => {
    try {
      setLoader(true);
      customerService.setAccessToken(accessToken)
      const response = await customerService.stripeAccount(id);
      setStripeData(response.data)
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getStripeAccount(params.id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, reloadData]);

  const handleReloadSuccess = () => {
    setReloadData(prevState => !prevState);
  };

  return (
    <>
      <Container>
        <ContainerAccount>
          <ButtonReturn onClick={() => navigate(`/admin/dashboard`, { state: { page: page } })}>
            Return to dashboard
          </ButtonReturn>
          {Object.keys(customderData).length && customderData.paymentStatus === 'DROP' ? <Box>
            <Alert severity="error">
              <AlertTitle>Dropp account</AlertTitle>
              <small>Drop reason: {customderData.paymentDroppedReason}</small>
              <br/>
              <small>Drop date: {customderData.paymentDroppedAt}</small>
            </Alert>
          </Box> : null}
          <BoxTicketMain>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button disabled={!Object.keys(customderData).length} onClick={() => setIsOpenEditAccountModal(true)} variant="outlined" color="primary" size="small">Edit</Button>
            </Box>
            <TitleBoxBootCamp>{customderData.name}</TitleBoxBootCamp>
            <Grid container>
              <Grid container>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Name</LabelName>
                    <LabelTextTitle>{customderData.fullName}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Title</LabelName>
                    <LabelTextTitle>{customderData.title}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Phone</LabelName>
                    <LabelTextTitle>{customderData.phone}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Email</LabelName>
                    <LabelTextTitle style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                      {customderData.email}
                    </LabelTextTitle>
                  </Box>
                </Grid>
              </Grid>
              <BoxLine />
              <Grid container>
                <Grid item xs={3}>
                  <BoxAddress>
                    <LabelName>Address</LabelName>
                    <LabelTextTitle>{customderData.address}</LabelTextTitle>
                  </BoxAddress>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Music Use</LabelName>
                    <LabelTextTitle>{customderData.musicUse}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Gym Type</LabelName>
                    <LabelTextTitle>{customderData.gymType}</LabelTextTitle>
                  </Box>
                </Grid>
              </Grid>
              <BoxLine />
              <Grid container>
                <Grid item xs={3}>
                  <Box>
                    <LabelClassCapacity>
                      # of Group Class Participant Capacity
                    </LabelClassCapacity>
                    <LabelTextTitle>{customderData.groupClassParticipantCapacity}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName># Classes per Week</LabelName>
                    <LabelTextTitle>{customderData.classesPerWeek}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Total Group Class Weekly Capacity</LabelName>
                    <LabelTextTitle>{customderData.totalGroupClassWeeklyCapacity}</LabelTextTitle>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box>
                    <LabelName>Social Events</LabelName>
                    <LabelTextTitle>No</LabelTextTitle>
                  </Box>
                </Grid>
              </Grid>
              <BoxLine />
              <Grid container>
                <Grid item xs={6}>
                  <LabelName>Total Members</LabelName>
                  <LabelTextTitle>{customderData.totalMembers}</LabelTextTitle>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <Button
                    onClick={() => setIsOpenRecalculateRatesModal(true)}
                    sx={{ color: 'white' }}
                    variant="contained"
                    color="primary"
                    disableElevation>
                      Recalculate Rates
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </BoxTicketMain>
            {
              customderData?.providers?.map((data) => (
                <>
                  {
                    (data.package)
                      ? <ViewProPackage data={data} reloadData={handleReloadSuccess} downloadCustomerAgreement={downloadCustomerAgreement} />
                      : <ViewProDefault data={data} reloadData={handleReloadSuccess} downloadCustomerAgreement={downloadCustomerAgreement} />
                  }
                </>
              ))
            }
          <BoxTicketMain>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <TitleBox>Totals</TitleBox>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <TitleTag>Payment Status </TitleTag>
                      <PaymentStatus
                        style={statusStyles[customderData.paymentStatus]}
                      >
                        {customderData.paymentStatus}
                      </PaymentStatus>
                      <Link href={stripeData.url} underline="none" target="_blank" rel="noopener noreferrer">
                        <LinksTag>Stripe Account <ArrowIcon/> </LinksTag>
                      </Link>
                    </Box>
                  </Grid>
                  {isThereASCAP ? (
                  <Grid item xs={6}>
                    <Box>
                      <TitleTag>Docusign</TitleTag>
                        <Button sx={{display: 'flex', flexDirection: 'column', padding: '0'}} onClick={() => {downloadDocusignFile(ASCAPData[0]?.link)}}>
                        <LinksTag> Download Agreement <ArrowIcon /> </LinksTag>
                      </Button>
                    </Box>
                  </Grid>) : ("") }
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={9}>
                    <GridBoxTagResult container >
                      <GridTags item xs={9}>
                        {customderData?.totals?.providers?.map((data, id) => (
                          <Tag key={id}>{data.name == '' ? data.category : data.name}</Tag>
                        ))}
                        {
                            Math.abs(customderData?.totals?.processingFee) > 0 && (
                                <Tag>Monthly 3% Processing Fee</Tag>
                            )
                        }
                        {
                            Math.abs(customderData?.totals?.rightManagementFee) > 0 && (
                                <Tag>Monthly Rights Management Fee</Tag>
                            )
                        }
                        {
                          Math.abs(customderData?.totals?.subTotalManagementFee) > 0
                            && (<Tag sx={{ color: "#F5A623"}}>PromoCode {customderData?.promoCode?.codeManagementFee}</Tag>)
                        }
                      </GridTags>
                      <Grid item xs={3} sx={{ paddingLeft: "1em" }}>
                        {customderData?.totals?.providers?.map((data, id) => (
                          <ResultTag key={id}>${data.total}</ResultTag>
                        ))}
                        {
                            Math.abs(customderData?.totals?.processingFee) > 0 && (
                                <ResultTag>${customderData?.totals?.processingFee}</ResultTag>
                            )
                        }
                        {
                            Math.abs(customderData?.totals?.rightManagementFee) > 0 && (
                                <ResultTag>${customderData?.totals?.rightManagementFee}</ResultTag>
                            )
                        }
                        {
                          Math.abs(customderData?.totals?.subTotalManagementFee) > 0
                            && (<ResultTag sx={{ color: "#F5A623", marginLeft: -1}}>-${Math.abs(customderData?.totals?.subTotalManagementFee)}</ResultTag>)
                        }
                      </Grid>
                    </GridBoxTagResult>
                    <Grid container sx={{ paddingTop: "0.5em" }}>
                      <GridResult item xs={8}>
                        <Tag>Total Monthly Stripe Payment</Tag>
                      </GridResult>
                      <GridResult item xs={4}>
                        <ResultTagFinal>$ {customderData?.totals?.total}</ResultTagFinal>
                      </GridResult>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BoxTicketMain>
        </ContainerAccount>
      </Container>
      <LoaderWidget loader={loader} />
      {
        isOpenEditAccountModal && <DialogEditAccount
          openDialog={isOpenEditAccountModal}
          setOpenDialog={setIsOpenEditAccountModal}
          data={customderData}
          onEditSuccess={setReloadData}
        />
      }
      {
        isOpenRecalculateRatesModal && <RecalculateRatesDialog
          data={customderData}
          openDialog={isOpenRecalculateRatesModal}
          setOpenDialog={setIsOpenRecalculateRatesModal}
          onSuccess={setReloadData}
        />
      }
    </>
  );
};

export default AccountPage;

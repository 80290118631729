import { useState } from 'react';

import PropTypes from 'prop-types';

import { Alert, Typography, Button, Dialog, DialogTitle } from '@mui/material';
import {
  BtnActionsDlgAsk, ContentSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import BusinessService from '../../core/services/business.service';

const RecalculateRatesDialog = ({
  data,
  openDialog,
  setOpenDialog,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setErrror] = useState(false);

  const businessService = new BusinessService();

  const recalculateRate = async (id) => {
    try {
      setLoading(true);
      await businessService.recalculateRates(id)
      .then(() => {
        setOpenDialog(false);
        return onSuccess();
      }).finally(() => {
        setLoading(false);
      });
    } catch (error) {
      setErrror(true);
      console.log(error);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <DialogTitle>
          Are you sure you want to Recalculate Rates?
        </DialogTitle>
        <ContentSectionDialog>
          <Typography>
            {`This will change the rates for ${data?.name} customer to the new PRO rates shown in admin moving forward.`}
          </Typography>
        </ContentSectionDialog>
        {error && <Alert severity="warning">Fail to recalculate rates, try again.</Alert>}
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant='outlined'
            color='primary'
            onClick={() => {
              recalculateRate(data.businessId);
            }}
          >
            Yes & Continue
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default RecalculateRatesDialog;

RecalculateRatesDialog.propTypes = {
  data: PropTypes.shape({
    businessId: PropTypes.number,
    name: PropTypes.string,
  }),
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

export default {
  formId: 'businessDetails',
  formField: {
    businessName: {
      name: 'name',
      label: 'Business Name *',
      requiredErrorMsg: 'Business name is required'
    },
    fullName: {
      name: 'full_name',
      label: 'User Full Name *',
      requiredErrorMsg: 'Full name is required'
    },
    email: {
      name: 'email',
      label: 'Email *',
      requiredErrorMsg: 'Email is required'
    },
    phoneNumber: {
      name: 'phone_number',
      label: 'Phone Number *',
      requiredErrorMsg: 'Phone Number is required'
    },
    title: {
      name: 'title',
      label: 'Title',
      requiredErrorMsg: 'Title is required'
    },
    address: {
      name: 'address',
      label: 'Address',
      requiredErrorMsg: 'Address is required'
    },
    state: {
      name: 'state',
      label: 'State',
      requiredErrorMsg: 'State is required'
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'City is required'
    },
    zipcode: {
      name: 'zip',
      label: 'Zip Code',
      requiredErrorMsg: 'Zip is required'
    },
    gymType: {
      name: 'type',
      label: 'Gym Type',
      requiredErrorMsg: 'Title is required'
    },
    totalMembers: {
      name: 'total_members',
      label: 'Total members',
      requiredErrorMsg: 'Title is required'
    },
    numberGroup: {
      name: 'number_group',
      label: '# of Group Class Participant Capacity',
      requiredErrorMsg: '# group is required'
    },
    classesPerWeek: {
      name: 'classes_per_week',
      label: '# Classes per week',
      requiredErrorMsg: 'Title is required'
    },
  }
};

/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Switch } from "@mui/material";
import {
  wrapperPros,
  optionPro,
} from "./pros.style";
import { ButtonReturn, ContainerAccount } from "../../assets/styles/account.styles";
import { TitlePage } from "../../assets/styles/annual-rates.style";
import LoaderWidget from "../../../core/widgets/loader/loader.widget";
import ProviderAdminsService from "../../../core/services/providers.admin.service";

const providerAdminsService = new ProviderAdminsService();

const ActiveProsPage = () => {

  const [loader, setLoader] = useState(false);
  const [optionsActivePros, setOptionsActivePros] = useState([]);
  const navigate = useNavigate();

  const handleChangeProActive = async (event, pro) => {
    try {
      setLoader(true);
      setOptionsActivePros((prevOptions) =>
        prevOptions.map((opt) => 
          (
            opt.id === pro.id 
            ? { ...pro, isActive: event.target.checked } 
            : opt
          )
        )
      );
      providerAdminsService.getAccessToken();
      await providerAdminsService.updateToggleStatus(pro.id);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };


  const getPros = async () => {
    try {
      setLoader(true);
      providerAdminsService.getAccessToken();
      const { data: dataProviders } = await providerAdminsService.getProviders();
      const filterProviders = dataProviders.filter((provider) => provider.shortName !== "FITRADIO");
      setOptionsActivePros(filterProviders);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  }

  useEffect(() => {
    getPros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <Container>
        <ContainerAccount>
          <ButtonReturn
            onClick={() => navigate('/admin/dashboard')}
          >
            Return to dashboard
          </ButtonReturn>
          <Box>
            <TitlePage style={{textAlign: 'center'}}>Active PROs</TitlePage>
          </Box>

          <Grid container>

            <Grid item md={4}/>
            <Grid item md={4} sx={wrapperPros}>
              <Grid container>

                {
                  optionsActivePros.map((pro, key)=>(
                    <Grid item xs={12} md={12} key={`pro${key}`}>
                      <Grid container sx={optionPro}>
                        <Grid item md={10}>
                          {pro.shortName}
                        </Grid>
                        <Grid item md={2}>
                          <Switch
                            checked={pro.isActive}
                            onChange={(e)=>{handleChangeProActive(e, pro)}}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                }
              
              </Grid>
            </Grid>
            <Grid item md={4}/>   
            <LoaderWidget loader={loader} />
          </Grid>
        </ContainerAccount>
      </Container>
    </>
  );
};

export default ActiveProsPage;

import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import Footer from './footer';
import HeaderWidget from './widgets/header/header.widget';

const useAuth = () => {
  const { user } = useSelector((state) => state.user);

  if(!user) return false;

  const accessToken = user.token;
  return accessToken ? true : false;
};

const AdminLayout = () => {
  const auth = useAuth();

  return auth ? (
    <>
      <HeaderWidget />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Navigate replace to='/login' />
  );
};

export default AdminLayout;

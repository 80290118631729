import AppService from './AppService';

class ProvidersService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('web/providers');
  }

  getProviders() {
    return this.http.get(`${this.path}`);
  }

  updatePaymentStartDate(providerId, body) {
    return this.http.post(`admin/agreement/created-date/${providerId}`, body)
  }

  dropProvider(providerId) {
    return this.http.post(`admin/agreement/drop-subscription/${providerId}`)
  }

  resendAgreementProvider(providerId) {
    return this.http.post(`admin/agreement/resend-agreement/${providerId}`)
  }

}

export default ProvidersService;

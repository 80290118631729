import background from '../../assets/images/background-login.jpg';

export const loginStyles = () => ({
  bgMain: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backdropFilter: 'blur(10px)',
  },
  backdropBlur: {
    height: '100vh',
    width: '100vw',
    backdropFilter: 'blur(4px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBox: {
    padding: '64px',
    width: { xs: '80%', md: '25%' },
    borderRadius: '6px',
  },
  welcome: {
    marginBottom: '32px',
    textAlign: 'center',
    h2: {
      fontFamily: 'Bebas Neue Bold',
      fontSize: '56px',
    },
  },
});

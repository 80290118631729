import React, {useState} from 'react';

import { Download } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector,
} from '@mui/x-data-grid';

import { PaginationStyles } from './custom-pagination.styles';
import { DashboardContext } from './dashboard.table';
import CustomerService from '../../core/services/customer.service';
import { useSelector } from 'react-redux';
import AppHelper from '../../core/helpers/AppHelper';
import { useUI } from '../../core/context/ui';
import LoaderWidget from '../../core/widgets/loader/loader.widget';

export const CustomPagination = () => {
  const { setRowsState, paymentStatus, providerSelected, getCustomers, sortModel } =
    React.useContext(DashboardContext);
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;

  const styles = PaginationStyles();

  const [loader, setLoader] = useState(false);
  const {snackbarUI} = useUI();

  const customerService = new CustomerService;

  const downloadAllFiles = async () => {
    try {
      setLoader(true);
      customerService.setAccessToken(accessToken);
      const response = await customerService.downloadAllFiles();
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `agreements.zip`;
      file.click();
      setLoader(false);
    }catch(error){
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  }

  const onPageChange = (nextPage) => {
    setRowsState((prev) => ({ ...prev, page: nextPage - 1 }));
    getCustomers({
      order: sortModel.field || '',
      direction: sortModel.sort || '',
      page: nextPage,
      paymentStatus: paymentStatus,
      provider: providerSelected,
    });
  };

  return (
    <>
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        siblingCount={0}
        boundaryCount={1}
        onChange={(event, value) => {
          onPageChange(value); 
        }}
        sx={styles.pagination}
      />
      <Box sx={styles.download}>
        <Button
          onClick={downloadAllFiles}
        >
          <Download />
          Download All
        </Button>
      </Box>
      <LoaderWidget loader={loader} />
    </>
  );
};

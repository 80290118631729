import PropTypes from 'prop-types';
import { Button, Dialog } from '@mui/material';

import {
  BtnActionsDlgAsk, ContentSectionDialog, TitleSectionDialog,
} from '../../assets/styles/admin-dashboard.dialog.styles';

const DialogPackageDelete = ({openDialog, deletePackage, handleDialogClose, packageId, isProvider}) => {

  return (
    <>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        PaperProps={{ sx: { padding: '16px' } }}
      >
        <TitleSectionDialog>Wait!</TitleSectionDialog>
        <ContentSectionDialog>Are you sure you want to delete
          this {isProvider ? 'provider' : 'package'} ?</ContentSectionDialog>
        <BtnActionsDlgAsk>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              deletePackage(packageId);
            }}
          >
            Delete
          </Button>
        </BtnActionsDlgAsk>
      </Dialog>
    </>
  );
};

export default DialogPackageDelete;

DialogPackageDelete.propTypes = {
  handleDialogClose: PropTypes.func,
  deletePackage: PropTypes.func,
  openDialog: PropTypes.bool,
  packageId: PropTypes.number,
  isProvider: PropTypes.bool,
};

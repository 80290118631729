import AppService from './AppService';

class ProviderAdminsService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/providers');
  }

  getProviders() {
    return this.http.get(`${this.path}`);
  }

  updateToggleStatus(id) {
    return this.http.patch(`${this.path}/toggle-status/${id}`);
  }

}

export default ProviderAdminsService;

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AdminRoute } from './admin.route';
import { WebRoute } from './web.route';

const AppRoute = () => (
  <BrowserRouter>
    <Routes>
      <Route path='*' element={<WebRoute />} />
      <Route exact path='/admin/*' element={<AdminRoute />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoute;

import PropTypes from 'prop-types';

import { Button, Dialog, DialogContent } from '@mui/material';

import {
  BtnActionsDlgScss, ContentSectionDialog, TitleSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';

const DialogSuccessfullDelete = ({ openDlgSuccessDelete, setOpenDlgSuccessDelete }) => {
  const handleDialogClose = () => {
    setOpenDlgSuccessDelete(false);
  };

  return (
    <Dialog
      style={{ zIndex: 1800 }}
      open={openDlgSuccessDelete}
      keepMounted
      onClose={handleDialogClose}
      PaperProps={{ sx: { padding: '16px' } }}
    >
      <div>
        <DialogContent style={{ width: 500, height: 150, padding: 0 }}>
          <TitleSectionDialog component='h2'>Successfully Deleted</TitleSectionDialog>
          <ContentSectionDialog>
            The selected account has been successfully deleted.
          </ContentSectionDialog>
        </DialogContent>
        <BtnActionsDlgScss>
          <Button variant='outlined' color='primary' onClick={handleDialogClose}>
            Close
          </Button>
        </BtnActionsDlgScss>
      </div>
    </Dialog>
  );
};

export default DialogSuccessfullDelete;

DialogSuccessfullDelete.propTypes = {
  openDlgSuccessDelete: PropTypes.bool,
  setOpenDlgSuccessDelete: PropTypes.func,
};

import { theme } from '../../../theme';

export const DashboardStyles = () => ({
  titleWrap: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    margin: { xs: '40px 0 15px', md: '40px 0 36px' },
    justifyContent: { xs: 'unset', md: 'space-between' },
    alignItems: { xs: 'unset', md: 'center' },
    '& h1': {
      color: '#010101',
      fontSize: { xs: '32px', md: '48px' },
      margin: '5px 0 10px',
    },
  },

  wrapperTable: {
    minHeight: 700,
    padding: '30px 40px',
    backgroundColor: '#FFF',
    borderRadius: '6px',
  },

  status_container: {
    width: '170px',
  },

  tableGrid: {
    minHeight: 500,
    maxHeight: 550,
    borderRadius: '6px',
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '0',
    fontSize: '16px',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeadersInner': {
      fontSize: '16px',
      '& .MuiDataGrid-columnHeader': {
        padding: '0',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        paddingLeft: '26px',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '700',
      },
      '& .status_header': {
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: 0,
        },
      },
    },
    '& .MuiDataGrid-row': {
      fontSize: { xs: '14px', md: '16px' },
    },
    '& .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '30px',
      padding: '0 25px',
    },
    '& .status_cell': {
      padding: 0,
    },
    '& .MuiDataGrid-virtualScroller': {
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(16, 16, 16, 0.5)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'center',
      border: 'none',
      borderTop: '1px solid #e0e0e0',
      position: 'absolute',
      width: '100%',
      paddingTop: '25px',
    },
    borderBottom: 'none',
  },

  title: {
    fontFamily: 'Bebas Neue',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    lineHeight: 0.9,
  },

  subTitle: {
    fontSize: '14px',
    marginBottom: 0,
    fontFamily: 'Montserrat',
  },

  wrapBtn: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: { xs: 'column', sm: 'row' },
    marginTop: '15px',
    '& .MuiButton-root': {
      '& .MuiSvgIcon-root': {
        padding: '0 5px 0 0',
      },
    },
  },

  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.primary.color,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    border: '2px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '2rem',
    fontSize: '14px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    padding: '10px 17px',
    textDecoration: 'none',
  },

  btnPrimaryLight: {
    backgroundColor: 'rgba(245, 166, 35, 0.12)',
    color: theme.palette.primary.main,
    border: '2px solid rgba(255, 255, 255, 0.12)',
  },

  downloadBtn: {
    fontSize: '12px',
    padding: '11px 17px',
    maxHeight: '40px',
    margin: 0,
  },

  ico: {
    marginRight: '12px',
    maxWidth: '12px',
    maxHeight: '15px',
  },

  dashboardTableStyle: {
    backgroundColor: theme.palette.primary.color,
    border: '1px solid #e8ecef',
    boxShadow: '0 16px 24px rgb(0 0 0 / 4%)',
    borderRadius: '4px',
    padding: '20px 15px',
    position: 'relative',
    marginBottom: '20px',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: { xs: '12px', md: '14px' },
      fontWeight: 'bold',
      verticalAlign: 'middle',
      fontFamily: 'Montserrat',
      padding: '0.75rem',
    },
  },

  dataGridStyles: {
    fontFamily: 'Montserrat',
    fontSize: { xs: '14px', md: '12px' },
    padding: '10px 20px',
    '& .MuiDataGrid-cell': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    '& .actionHeader': {
      display: 'none',
    },

    '& .MuiBox-root': {
      textAlign: 'right',
      padding: '20px 0',
      '& .MuiButton-root': {
        fontFamily: 'Montserrat',
        color: '#010100',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '2px solid #010100',
        borderRadius: '30px',
        padding: '10px 40px',
        '& .MuiSvgIcon-root': {
          marginRight: '10px',
        },
      },
    },
  },

  filtersBox: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      marginLeft: '10px',
    },
    '& .MuiFormControl-root + .MuiFormControl-root': {
      marginLeft: '20px',
    },
    padding: '10px 0',
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
    },
  },

  subTitleAdmin: {
    fontFamily: 'Montserrat',
    fontSize: { xs: '12px', md: '18px' },
    fontWeight: 'bold',
    marginTop: '20px',
  },

  select: {
    fontFamily: 'Montserrat SemiBold, sans-serif',
    fontSize: '14px',
    boxSizing: 'border-box',
    minHeight: '32px',
    border: 'none',
    borderRadius: '50px',
    lineHeight: '1.5',
    '&::before, &::after': {
      content: 'unset',
    },
    '&:hover': {
      '&::before': {
        content: 'unset',
      },
    },
    '& .MuiSelect-select': {
      '&.MuiInputBase-input': {
        '&.MuiInput-input': {
          padding: '0 0 0 20px',
        },
      },
    },
    '& .MuiInput-input': {
      '&:focus': {
        backgroundColor: 'unset',
      },
    },
    '& .MuiSvgIcon-root': {
      right: '15px',
    },
  },

  statusActive: {
    background: 'rgba(39, 174, 96, 0.12)',
    color: 'rgba(39, 174, 96, 1)',
    '& .MuiSvgIcon-root': {
      fill: 'rgba(39, 174, 96, 1)',
    },
  },

  statusInactive: {
    background: 'rgba(235, 87, 87, 0.12)',
    color: 'rgba(235, 87, 87, 1)',
    '& .MuiSvgIcon-root': {
      fill: 'rgba(235, 87, 87, 1)',
    },
  },
  paper: {
    padding: '4px',
    borderRadius: '8px',
    border: '1px solid rgba(200, 200, 200, 0.8)',
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      fontFamily: 'Montserrat SemiBold, sans-serif',
      borderRadius: '8px',
      padding: '4px 16px',
    },
    '& .MuiMenuItem-root[data-value=inactive]': {
      color: 'rgba(235, 87, 87, 1)',
      backgroundColor: 'rgba(235, 87, 87, 0.12)',
      '&.Mui-selected': {
        backgroundColor: 'rgba(235, 87, 87, 0.12)',
      },
      '&:hover': {
        backgroundColor: 'rgba(235, 87, 87, 0.24)',
      },
    },
    '& .MuiMenuItem-root[data-value=active]': {
      marginBottom: '6px',
      color: 'rgba(39, 174, 96, 1)',
      backgroundColor: 'rgba(39, 174, 96, 0.12)',
      '&.Mui-selected': {
        backgroundColor: 'rgba(39, 174, 96, 0.12)',
      },
      '&:hover': {
        backgroundColor: 'rgba(39, 174, 96, 0.24)',
      },
    },
  },
});

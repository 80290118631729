import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'user',
  initialState: {
    app: {
      openLogin: false,
    },
  },
  reducers: {
    loginStatus: (state) => {
      state.app.openLogin = !state.app.openLogin;
    },
  },
});

export const { loginStatus } = appSlice.actions;

export default appSlice.reducer;

import businessModel from "./business.model";

const {
	formField: {
    businessName,
		fullName,
		email,
		phoneNumber,
		title,
    gymType,
    classesPerWeek,
    numberGroup,
    totalMembers,
    address,
    state,
    city,
    zipcode
	} 
} = businessModel;

const businessInitialValues = (business) => ({
  [businessName.name]: business.name,
	[fullName.name]: business.fullName,
	[email.name]: business.email,
	[phoneNumber.name]: business.phone,
	[title.name]: business.title,
  [gymType.name]: business.gymType,
  [classesPerWeek.name]: business.classesPerWeek,
  [numberGroup.name]: business.groupClassParticipantCapacity,
  [totalMembers.name]: business.totalMembers,
  [address.name]: business.mainAddress,
  [state.name]: { value: business.stateId, label: business.state },
  [city.name]: { value: business.city, label: business.city },
  [zipcode.name]: business.zipCode,
});

export default businessInitialValues;

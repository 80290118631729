import {theme} from "../../../theme";

export const sx = {
  '&:hover': {
    transition: 'all 0.2s',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.color,
    '& .MuiListItemIcon-root': {
      transition: 'all 0.2s',
      color: theme.palette.primary.color,
    },
  },
};

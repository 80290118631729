export const SidebarStyles = () => ({
  wrapperDrawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: 'black !important',
      opacity: '0.96',
    }
  },
  itemList: {
    padding: '0px 20px',
    cursor: 'pointer',
    '& .MuiListItemText-primary': {
      fontSize: '18px',
      fontWeight: 'bold',
      backgroundColor: '#f5a623 !important',
      color: '#ffffff !important',
      borderRadius: '12px !important',
      padding: '3px 24px!important',
      textAlign: 'center !important',
      opacity: '1 !important',
      fontFamily: 'Bebas Neue',
    }
  }
});

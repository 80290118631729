import * as Yup from "yup";
import businessModel from "./business.model";

const {
  formField: {
    businessName,
    fullName,
    email,
    phoneNumber,
    title,
    address,
    state,
    city,
    zipcode,
    numberGroup,
    classesPerWeek,
    totalMembers,
    gymType,
  },
} = businessModel;

export default Yup.object().shape({
  [fullName.name]: Yup.string().required(fullName.requiredErrorMsg),
  [email.name]: Yup.string().email(email.requiredErrorMsg),
  [phoneNumber.name]: Yup.string()
    .required(phoneNumber.requiredErrorMsg)
    // eslint-disable-next-line no-useless-escape
    .matches(
      "^[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4}$",
      "The phone number must be this format: (123)123-1234"
    )
    .typeError("you must specify a number"),
  [title.name]: Yup.string().nullable(),
  [gymType.name]: Yup.string().nullable(),
  [businessName.name]: Yup.string().required(`${businessName.requiredErrorMsg}`),
  [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
  [state.name]: Yup.object().required(`${state.requiredErrorMsg}`).nullable(),
  [city.name]: Yup.object().required(`${city.requiredErrorMsg}`).nullable(),
  [zipcode.name]: Yup.string()
    .matches(/([1-9][0-9]*)|0/, "Must be a number")
    .required(`${zipcode.requiredErrorMsg}`)
    .test("zipCode", "Must be a valid zip code", (value) => {
      if (value) return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
      return true;
    }),
  [totalMembers.name]: Yup.string()
    .required(`${totalMembers.requiredErrorMsg}`)
    .matches(/^[1-9][0-9]*$/, "Must be a number"),
  [numberGroup.name]: Yup.string()
    .required(`${numberGroup.requiredErrorMsg}`)
    .matches(/^[1-9][0-9]*$/, "Must be a number")
    .test("numberGroups", "Must be a number greater than 0", (value) => {
      return /^[1-9][0-9]*$/.test(value) && value[0] !== "0";
    })
    .test({
      name: "Greater",
      message:
        "# of Group Class Participant Capacity x # of Classes per Week cannot be greater than 15000",
      test: function (value) {
        if (value) {
          return (
            parseInt(this.parent.classes_per_week * value) < 15000 && true
          );
        }
        return true;
      },
    }),
  [classesPerWeek.name]: Yup.string()
    .required(`${classesPerWeek.requiredErrorMsg}`)
    .matches(/^[1-9][0-9]*$/, "Must be a number")
    .test("classesPerWeek", "Must be a number greater than 0", (value) => {
      return /^[1-9][0-9]*$/.test(value) && value[0] !== "0";
    })
    .test({
      name: "Greater1",
      message:
        "# of Group Class Participant Capacity x # of Classes per Week cannot be greater than 15000",
      test: function (value) {
        if (value) {
          return (
            parseInt(this.parent.number_group * value) < 15000 && true
          );
        }
        return true;
      },
    }),
});

import { capitalize as _capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Dialog, Typography } from '@mui/material';

import {
  BtnActionsDlgScss, ContentSectionDialog, TitleSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';

const DialogSuccess = ({ openDlg, message, handleDialogClose, setOpenDlg }) => {
  return (
    <Dialog
      style={{ zIndex: 1800 }}
      open={openDlg}
      keepMounted
      onClose={handleDialogClose}
      PaperProps={{ sx: { padding: '16px' } }}
    >
      <div>
        <ContentSectionDialog>
          <TitleSectionDialog component='h2'>
            {message.includes('taken') ? 'Oops!' : 'Success!'}
          </TitleSectionDialog>
          <Typography>{_capitalize(message)}</Typography>
        </ContentSectionDialog>
        <BtnActionsDlgScss>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              handleDialogClose();
              setOpenDlg(false);
            }}
          >
            Close
          </Button>
        </BtnActionsDlgScss>
      </div>
    </Dialog>
  );
};

export default DialogSuccess;

DialogSuccess.propTypes = {
  message: PropTypes.string,
  openDlg: PropTypes.bool,
  setOpenDlg: PropTypes.func,
  handleDialogClose: PropTypes.func,
};

import { useState } from 'react';

import { Autocomplete, fieldToTextField } from 'formik-mui';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name, label, defaultValue } = field;
  const [medValue, setMedValue] = useState(defaultValue || null);

  const handleChange = (value) => {
    props.getValueChild(value);
    setFieldValue(name, value);
    setMedValue(value);
  };

  return (
    <Autocomplete
      {...props}
      {...field}
      value={medValue}
      onChange={(_, value) => handleChange(value)}
      onBlur={() => setTouched({ [name]: true })}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(item, current) => item.value === current.value}
      renderInput={(props) => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          label={label}
          sx={{ marginTop: '0' }}
        />
      )}
    />
  );
};

export default FormikAutocomplete;

FormikAutocomplete.propTypes = {
  form: PropTypes.object,
  textFieldProps: PropTypes.object,
  getValueChild: PropTypes.func,
};

import { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import {
  isEmpty as _isEmpty, isUndefined as _isUndefined, mapKeys as _mapKeys,
} from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button, Grid, Typography } from '@mui/material';

import UserService from '../../core/services/admin.service';
import InputFieldPassword from '../../core/widgets/input.password.widget';
import InputField from '../../core/widgets/input.widget';
// import LoaderWidget from '../../core/widgets/loader/loader.widget';
import {
  BtnActionsDlgAsk, ContentSectionDialog, DialogCreate, TitleSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import DialogSuccess from './dialogSuccess';

const DialogCreateAdmin = ({ setOpenDialog, adminID, openDialog, title, getUsers, setOpenDlg }) => {
  const [adminValues, setAdminValues] = useState({});
  const { user } = useSelector((state) => state.user);
  const accessToken = user.token;
  const formikRef = useRef();

  const handleDialogClose = () => {
    setOpenDialog(false); // Use the prop.
  };

  const [openDlgSuccess, setOpenDlgSuccess] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);

  const userService = new UserService();
  userService.setAccessToken(accessToken);

  const [message, setMessage] = useState('');

  const AdminSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    title: Yup.string().required('Title is required'),
    email: Yup.string().email('Please enter a valid e-mail').required('Required'),
    password: _isUndefined(adminID)
      ? Yup.string('Enter your password')
          .min(8, 'Minimum 8 characters')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            'Must have at least one Uppercase and one number'
          )
          .required('Password is required')
      : Yup.string('Enter your password')
          .min(8, 'Minimum 8 characters')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            'Must have at least one Uppercase and one number'
          )
          .notRequired('Password is required'),
  });

  const handleSubmit = async (values) => {
    let data = {};
    if (!_isUndefined(adminID)) {
      _mapKeys(adminValues, (value, key) => {
        if ((_isEmpty(value) || value !== '') && value !== values[key]) {
          data.password = values?.password
          data[key] = values[key];          
        }
      });
    }

    try {
      setLoader(true);
      userService.setAccessToken(accessToken);
      const response = !_isUndefined(adminID)
        ? await userService.update(adminID, data)
        : await userService.createUser(values);
      setMessage(response.data.message);
      setOpenDlgSuccess(true);
      setTimeout(() => {
        getUsers({});
      }, 500);
    } catch (error) {
      setLoader(false);
      setMessage(error.response.data.message);
      setOpenDlgSuccess(true);
    }
  };

  const getAdmin = async (id) => {
    try {
      setLoader(true);
      userService.setAccessToken(accessToken);
      const response = await userService.readUser(id);
      setAdminValues({
        fullName: response.data.fullName,
        title: response.data.title || '',
        email: response.data.email,
      });
      if (formikRef.current) {
        formikRef.current.setValues({
          ...formikRef.current.initialValues,
          fullName: response.data.fullName,
          title: response.data.title || '',
          email: response.data.email,
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (adminID) {
      getAdmin(adminID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogCreate open={openDialog} onClose={handleDialogClose}>
        <TitleSectionDialog>{title ? title : 'Create new admin'}</TitleSectionDialog>
        <ContentSectionDialog>
          <Typography className='subtitle'>Complete the neccesary data</Typography>
          <Formik
            initialValues={{}}
            innerRef={formikRef}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={AdminSchema}
          >
            {(props) => {
              const { values } = props;
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography className='label'>Name</Typography>
                      <InputField
                        name={'fullName'}
                        label={'Name'}
                        fullWidth
                        value={values.fullName || ''}
                        autoComplete='fullName'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className='label'>Title (optional)</Typography>
                      <InputField
                        name={'title'}
                        label={'Title'}
                        fullWidth
                        value={values.title || ''}
                        autoComplete='title'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className='label'>Email Account</Typography>
                      <InputField
                        name={'email'}
                        label={'Email'}
                        fullWidth
                        value={values.email || ''}
                        autoComplete='email'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className='label'>Password</Typography>
                      <InputFieldPassword
                        name={'password'}
                        label='Type password here...'
                        value={values.password || ''}
                        fullWidth
                        autoComplete='password'
                      />
                    </Grid>
                  </Grid>
                  <BtnActionsDlgAsk>
                    <Button
                      variant='outlined'
                      color='secondary'
                      onClick={() => {
                        handleDialogClose();
                        setOpenDlg(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant='outlined' color='primary' type='submit'>
                      Send
                    </Button>
                  </BtnActionsDlgAsk>
                </Form>
              );
            }}
          </Formik>
        </ContentSectionDialog>
        {/* <LoaderWidget loader={loader} /> */}
      </DialogCreate>
      {openDlgSuccess && (
        <DialogSuccess
          openDlg={openDlgSuccess}
          setOpenDlg={setOpenDlgSuccess}
          message={message}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
};

export default DialogCreateAdmin;

DialogCreateAdmin.propTypes = {
  openDialog: PropTypes.bool,
  values: PropTypes.node,
  setOpenDialog: PropTypes.func,
  handleChange: PropTypes.func,
  title: PropTypes.string,
  adminID: PropTypes.number,
  setFieldValue: PropTypes.any,
  getUsers: PropTypes.func,
  setOpenDlg: PropTypes.func,
};

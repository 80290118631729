import {
  BtnActionsDlgAsk,
  ContentSectionDialog,
  DialogCreate,
} from "../../../assets/styles/admin-dashboard.dialog.styles";
import Grid from "@mui/material/Grid";
import RadioGroupWidget from "../../../../core/widgets/radio.group.widget";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";
import {Button, Typography} from "@mui/material";
import InputField from "../../../../core/widgets/input.widget";
import CheckboxGroupWidget from "../../../../core/widgets/checkbox.group.widget";
import * as Yup from "yup";
import ErrorWidget from "../../../../core/widgets/error/error.widget";
import * as React from "react";

const PackageProviderDialog = ({setOpenDialog, openDialog, providersValues, categoriesValues, handleProvider, message}) => {
  const handleDialogClose = () => {
    setOpenDialog(false); // Use the prop.
  };

  const initialValues = {
    providers: '',
    isBgMusic: '',
    rate: ''
  }

  const validationSchema = Yup.object().shape({
    providers: Yup.array().min(1, 'You should select at least 1 provider')
      .of(Yup.string().required()).required(`Provider Type is required`),
    isBgMusic: Yup.string().required('You should select at least 1 music place')
      .test('at-least-one', 'You should select at least 1 music place', (value) => {
        return !!value;
      })
      .required('Music place selection is required'),
    rate: Yup.string()
      .required(`Annual Rate  is required`)
      .matches(/^[0-9]+(\.[0-9]{1,2})?$/, 'Must be a number')
  });

  return (
    <DialogCreate open={openDialog} onClose={handleDialogClose}>
      <ContentSectionDialog>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleProvider(values)}
        >
          {() => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CheckboxGroupWidget
                      label={'providers'}
                      name={'providers'}
                      options={providersValues}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroupWidget
                      name={'isBgMusic'}
                      options={categoriesValues}
                    />
                  </Grid>
                  <Grid item xs={2}/>
                  <Grid item xs={2}>
                    <Typography className='label' sx={{marginTop: '15px'}}>Annual Rate</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InputField
                      name={'rate'}
                      label={'Annual Rate'}
                      sx={{marginBottom: '0px'}}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ErrorWidget message={message}></ErrorWidget>
                  </Grid>
                </Grid>
                <BtnActionsDlgAsk>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      handleDialogClose();
                      setOpenDialog(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant='outlined' color='primary' type='submit'>
                    Save
                  </Button>
                </BtnActionsDlgAsk>
              </Form>
            )
          }}
        </Formik>
      </ContentSectionDialog>
    </DialogCreate>
  );
}

export default PackageProviderDialog;

PackageProviderDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  providersValues: PropTypes.array,
  categoriesValues: PropTypes.array,
  handleProvider: PropTypes.func,
  message: PropTypes.string,
};

import { Navigate, Route, Routes } from 'react-router-dom';

import AccountPage from '../../pages/account/account.page';
import AnnualRatesPage from '../../pages/admin/annualrates/annual-rates.page';
import DashboardBranch from '../../pages/DashboardBranch';
import AdminLayout from '../layouts/admin.layout';
import ActiveProsPage from '../../pages/admin/pros/active-pros.page';
import PackagePage from "../../pages/admin/package/package.page";
import PackageCreatePage from "../../pages/admin/package/create/package.create.page"

export const AdminRoute = () => (
  <Routes>
    <Route element={<AdminLayout />}>
      <Route path='*' element={<Navigate to='/admin/dashboard' />} />
      <Route exact path='/dashboard' element={<DashboardBranch />} />
      <Route exact path='/customer/:id' element={<AccountPage />} />
      <Route exact path='/annualrates' element={<AnnualRatesPage />} />
      <Route exact path='/active-pros' element={<ActiveProsPage />} />
      <Route exact path='/package' element={<PackagePage />} />
      <Route exact path='/package/create' element={<PackageCreatePage />} />
      <Route exact path='/package/edit/:packageId' element={<PackageCreatePage />} />
    </Route>
  </Routes>
);

import { serialize } from 'object-to-formdata';

import AppService from './AppService';

class AuthService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/auth');
  }

  login(data) {
    const formData = serialize(data);
    return this.http.post(`${this.path}/login`, formData);
  }

  me() {
    return this.http.get(`${this.path}/me`);
  }
}

export default AuthService;

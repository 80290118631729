import { useField } from 'formik';
import { at } from 'lodash';

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const Input = styled(TextField)({
  marginBottom: '20px',
});

export default function InputField(props) {
  const { ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <Input
      type='text'
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
    />
  );
}

import AppService from './AppService';

class ResourcesService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/resources');
  }

  list(params = '') {
    const queryParams = params ? params : '';
    return this.http.get(`${this.path}${queryParams}`);
  }
}

export default ResourcesService;

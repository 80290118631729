import * as React from 'react';

import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector,
} from '@mui/x-data-grid';

import { PaginationStyles } from "../custom/custom-pagination.styles";
import {PackageContext} from "./package.table";


export const PackagePagination = () => {
  const { setRowsState, getPackages, sortModel } = React.useContext(PackageContext);
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const styles = PaginationStyles();

  const onPageChange = (nextPage) => {
    setRowsState((prev) => ({ ...prev, page: nextPage - 1 }));
    getPackages({
      order: sortModel.field || '',
      direction: sortModel.sort || '',
      page: nextPage,
    });
  };

  return (
    <>
      <Pagination
        color='primary'
        count={pageCount}
        page={page + 1}
        siblingCount={0}
        boundaryCount={1}
        onChange={(event, value) => {
          onPageChange(value);
        }}
        sx={styles.pagination}
      />
    </>
  );
};

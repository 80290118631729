import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { SidebarStyles } from './sidebar.style';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ReportsService from '../../../services/reports.service';
import { useSelector } from 'react-redux';
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../context/ui';
import LoaderWidget from '../../../widgets/loader/loader.widget';

const Sidebar = ({setActiveSidebar, activeSidebar}) => {

  const styles = SidebarStyles();
  const navigate = useNavigate();
  const { snackbarUI } = useUI();

  const reportsService = new ReportsService();
  const { user } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const accessToken = user?.token;

  const handleRedirectPath = (path) => {
    navigate(path);
    setActiveSidebar(!activeSidebar);
  }

  const onDownloadExcel = async () => {
    try {
      setLoader(true);
      reportsService.setAccessToken(accessToken)
      const response = await reportsService.getCustomersReport();
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `music_licensing_report_${Moment().format('YYYYMMDD')}.xlsx`;
      file.click();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <Drawer variant="permanent" sx={styles.wrapperDrawer}>
      <List sx={{ backgroundColor: 'black', color: 'white'}}>
        <ListItem>
          <IconButton
            aria-label="close menu"
            sx={{color: 'white', margin: 'auto'}}
            onClick={()=>{setActiveSidebar(!activeSidebar)}}
          >
            <CloseIcon />
          </IconButton>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ANNUAL RATES"
            onClick={()=>{handleRedirectPath('/admin/annualrates')}}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="VIEW ADMINS"
            onClick={()=>{handleRedirectPath('/admin/dashboard?view=admins')}}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="VIEW CUSTOMERS"
            onClick={()=>{handleRedirectPath('/admin/dashboard?view=customers')}}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ACTIVE PROS"
            onClick={()=>{handleRedirectPath('/admin/active-pros')}}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="PACKAGE"
            onClick={()=>{handleRedirectPath('/admin/package')}}
            sx={styles.itemList}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="ML REPORT"
            onClick={onDownloadExcel}
            sx={styles.itemList}
          />
        </ListItem>
      </List>
      <LoaderWidget loader={loader} />
    </Drawer>
  );
};

Sidebar.propTypes = {
  setActiveSidebar: PropTypes.func.isRequired,
  activeSidebar: PropTypes.bool.isRequired,
};

export default Sidebar;

import React from 'react';

import { Box, CssBaseline } from '../widgets/MaterialUI';

const Footer = () => {
  const styles = useStyles();

  return (
    <>
      <CssBaseline />
      <Box component='footer' sx={styles.footer} />
    </>
  );
};

export default Footer;

const useStyles = () => ({
  footer: {
    marginTop: '8px',
    width: '100%',
    height: '80px',
    backgroundColor: '#FFF',
  },
});

import AppService from './AppService';

class BusinessService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('admin/business');
  }

  editBusiness(id, body) {
    return this.http.patch(`${this.path}/${id}`, body);
  }

  recalculateRates(id) {
    return this.http.post(`${this.path}/recalculate-rates/${id}`);
  }

}

export default BusinessService;

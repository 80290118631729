import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  BtnActionsDlgAsk, ContentSectionDialog, DialogCreate, TitleSectionDialog,
} from '../assets/styles/admin-dashboard.dialog.styles';
import { useUI } from '../../core/context/ui.js';
import AppHelper from '../../core/helpers/AppHelper.js';
import CustomerService from '../../core/services/customer.service';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import InputField from '../../core/widgets/input.widget';
import customerModel from './models/customer.model';
import customerInitialValues from './models/customer.initial.values';
import validationSchema from './models/validation.schema';
import SelectField from '../../core/widgets/select.widget';
import {capitalize} from 'lodash'

const statusOptions = ['Active', 'Inactive'];

const DialogEditCustom = (props) => {

  const { snackbarUI } = useUI();
  const { user } = useSelector((state) => state.user);
  const accessToken = user?.token;

  const { formId, formField } = customerModel;
  const formikRef = useRef();
  const [loader, setLoader] = useState(false);
  const customService = new CustomerService();
  
  const getCustomInfo = async (id) => {
    try {
      setLoader(true);
      customService.setAccessToken(accessToken);
      const res = await customService.getCustomer(id);
      if (formikRef.current) {
        formikRef.current.setValues({
          email: res.data.email,
          fullName: res.data.fullName,
          password: '',
          phoneNumber: res.data.phoneNumber,
          status: capitalize(res.data.status),
          title: res.data.title,
        });
      }
      setLoader(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  }

  useEffect(() => {
    getCustomInfo(props.id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

  const handleDialogClose = () => {
    props.setOpenDialog(false);
  };

  const onSubmit = async (values) => {
    const data = {
      full_name: values.fullName,
      phone_number: values.phoneNumber,
      password: values.password,
      status: values.status,
      title: values.title,
    };
    setLoader(true);
    try {
      customService.setAccessToken(accessToken);
      await customService.editCustomer(props.id, data);
      props.setOpenDialog(false);
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      setLoader(false);
    }
  };

  return (
    <>
      <DialogCreate open={props.openDialog} onClose={handleDialogClose}>
        <TitleSectionDialog component='div'>
          <Typography variant='h4'>Edit customer</Typography>
        </TitleSectionDialog>
        <ContentSectionDialog>{}
          <Formik 
            initialValues={customerInitialValues} 
            validationSchema={validationSchema} 
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
                <Form id={formId} component='form'>
                  <Grid container columnSpacing={2}>
                  <Grid item xs={12} md={6}>
                        <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label' htmlFor='fullName'>
                            Full Name
                          </Typography>
                        </Box>
                        <InputField
                          fullWidth
                          name={formField.fullName.name}
                          label={formField.fullName.label}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label' htmlFor='phoneNumber'>
                            Phone Number 
                          </Typography>
                        </Box>
                        <InputField
                          fullWidth
                          name={formField.phoneNumber.name}
                          label={formField.phoneNumber.label}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label' htmlFor='email'>
                            Email
                          </Typography>
                        </Box>
                        <InputField
                           disabled
                           fullWidth
                           name={formField.email.name}
                           label={formField.email.label}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label' htmlFor='password'>
                            Password
                          </Typography>
                        </Box>
                        <InputField
                          fullWidth
                          name={formField.password.name}
                          label={formField.password.label}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label' htmlFor='title'>
                            Title
                          </Typography>
                        </Box>
                        <InputField
                          fullWidth
                          name={formField.title.name}
                          label={formField.title.label}
                        />
                      </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <Box>
                        <Box sx={{ margin: '0 0 10px 0' }}>
                          <Typography component='label'>
                            Status
                          </Typography>
                        </Box>
                        <SelectField
                          name={formField.status.name}
                          label={formField.status.label}
                          data={statusOptions}
                          fullWidth
                        />
                      </Box>
                      </Grid>
                  </Grid>
                  <BtnActionsDlgAsk sx={{ margin: '10px 0 0 0' }}>
                    <Button variant='outlined' color='secondary' onClick={handleDialogClose}>
                      Cancel
                    </Button>
                    <Button variant='outlined' color='primary' type='submit'>
                      Save
                    </Button>
                  </BtnActionsDlgAsk>
                </Form>
          </Formik>
        </ContentSectionDialog>
      <LoaderWidget loader={loader} />
      </DialogCreate>
    </>
  );
};

export default DialogEditCustom;

DialogEditCustom.propTypes = {
  openDialog: PropTypes.bool,
  id: PropTypes.number,
  setOpenDialog: PropTypes.func,
  handleChange: PropTypes.func,
};
